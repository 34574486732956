import React, { useContext, useEffect } from 'react';
import { Accordion, AccordionSummary, Grid, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from 'components/Table';
import { ReportContext } from 'providers/ReportProvider';
import { ModelsContext } from 'providers/ModelsProvider';
import { ENTITY_COMPANY, ENTITY_BANK, ENTITY_PRIVATE_COMPANY, TABLE_HEADERS } from 'constants';
import { parseIndicator } from 'utils';
import { ENTITY_SUPPLIER } from 'constants';

const EditorBalanceSheet = () => {
  const { report, period, entityType, balance, setBalance } = useContext(ReportContext);
  const { balanceItems } = useContext(ModelsContext);

  const parseBalance = (values, entityType, period) => {
    let keys = [];
    const indicators = [];

    if (entityType === ENTITY_COMPANY) {
      keys = balanceItems.company.map((item) => item.code);
    } else if (entityType === ENTITY_BANK) {
      keys = balanceItems.bank.map((item) => item.code);
    } else if (entityType === ENTITY_PRIVATE_COMPANY) {
      keys = balanceItems.privateCompany.map((item) => item.code);
    } else if (entityType === ENTITY_SUPPLIER) {
      keys = balanceItems.supplier.map((item) => item.code);
    }

    keys.forEach((key, index) => {
      const indicator = values[key];

      indicators.push({
        id: index,
        field: indicator.metadata.name,
        value: parseIndicator(indicator, period),
        code: key,
        indicatorId: indicator.metadata.id,
      });
    });
    return indicators;
  };

  const handleOnChange = (event, id) => {
    const temp = [...balance];
    temp[id].value = event.target.value;
    setBalance(temp);
  };

  useEffect(() => {
    if (entityType === ENTITY_COMPANY) setBalance(balanceItems.company);
  }, [balanceItems, entityType, setBalance]);

  useEffect(() => {
    if (!report) return;

    const { indicators, type } = report;
    const res = parseBalance(indicators, type, period);
    setBalance(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, period]);

  useEffect(() => {
    if (report) return;

    if (entityType === ENTITY_BANK) {
      setBalance(balanceItems.bank);
    }

    if (entityType === ENTITY_COMPANY) {
      setBalance(balanceItems.company);
    }

    if (entityType === ENTITY_PRIVATE_COMPANY) {
      setBalance(balanceItems.privateCompany);
    }

    if (entityType === ENTITY_SUPPLIER) {
      setBalance(balanceItems.supplier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, report]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Balance Sheet</Typography>
      </AccordionSummary>

      <Divider />

      {balance && (
        <Grid container padding={2} gap={2}>
          <Table headers={TABLE_HEADERS} rows={balance} onChange={handleOnChange} />
        </Grid>
      )}
    </Accordion>
  );
};

export default EditorBalanceSheet;
