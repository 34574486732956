import React, { useContext } from 'react';
import { EntitiesLayout, EntityItem } from './Entities.styles';
import { CompanyLogo } from 'components/CompanyLogo';
import { camelCase } from 'utils';
import { ReportContext } from 'providers/ReportProvider';
import { EntitiesContext } from 'providers/EntitiesProvider';
import { getEntityPermissions, getReport } from 'services/ApiScore';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';

export const Entities = ({ entities }) => {
  const { setReport, setPermissions } = useContext(ReportContext);
  const { currentEntityId, setCurrentEntityId, isPending } = useContext(EntitiesContext);

  const handleEntityClick = async (id) => {
    if (currentEntityId === id) return;

    setCurrentEntityId(id);

    try {
      const { data } = await getReport(id);
      setReport(data);

      const { data: results } = await getEntityPermissions(id);
      setPermissions(results.entityPermissions);
    } catch {
      toast.error('Download error');
    }
  };

  return (
    <EntitiesLayout>
      {!isPending ? (
        entities.map((entity) => {
          const { entityId, name, score } = entity;
          return (
            <EntityItem
              key={entityId}
              onClick={() => handleEntityClick(entityId)}
              className={entityId === currentEntityId ? 'is-active' : ''}
            >
              <CompanyLogo name={camelCase(name)} />
              <span>{name}</span>
              <span>{score}</span>
            </EntityItem>
          );
        })
      ) : (
        <Loader />
      )}
    </EntitiesLayout>
  );
};
