import { object, string } from 'yup';

const ClientFormValidation = object().shape({
  name: string().required(),
  shortname: string().required(),
  code: string().required().min(3).max(3),
  country: string().required(),
  active: string().required(),
});

export default ClientFormValidation;
