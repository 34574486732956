import styled, { css } from 'styled-components';
import { device } from 'styles/size';

export const EntitiesLayout = styled.div`
  display: grid;
  gap: 0.2rem;
  align-content: start;

  max-height: calc(100vh - 200px);

  overflow-y: auto;
  @media ${device.mobileL}, ${device.tablet} {
    height: 300px;
  }
`;

const baseItem = css`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};

  &:focus {
    color: ${(props) => props.theme.palette.text.primary};
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    transition: 0.5s background-color ease-in-out;
  }

  &.is-active {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

export const EntityItem = styled.div`
  ${baseItem};
`;

export const EntityItemCompare = styled.div`
  ${baseItem};
`;
