import React, { useContext, useEffect, useState } from 'react';
import {
  MenuItem,
  Accordion,
  AccordionSummary,
  Typography,
  TextField,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReportContext } from 'providers/ReportProvider';
import { getReport } from 'services/ApiScore';
import { toast } from 'react-toastify';
import {
  FILE_HEIGHT,
  FILE_WIDTH,
  MAX_IMAGE_SIZE,
  FILE_EXTENSION,
  FILE_PNG,
  ENTITY_COMPANY,
} from 'constants';
import { uploadLogo } from 'services/ApiScore';
import { useClearFilter } from 'hooks/useClearFilter';
import { getFileExtension, getImageDimensions } from 'utils';
import { EntitiesContext } from 'providers/EntitiesProvider';
import Loader from 'components/Loader';
import { parseISO } from 'date-fns';
import { useFormContext, Controller } from 'react-hook-form';
import { ModelsContext } from 'providers/ModelsProvider';
import { useTranslation } from 'react-i18next';
import useIsPrivateCompany from 'hooks/useIsPrivateCompany';
import useIsSupplier from 'hooks/useIsSupplier';

const EditorEntity = () => {
  const { clear } = useClearFilter();
  const {
    report,
    setReport,
    period,
    periods,
    setPeriods,
    setPeriod,
    entityType,
    setEntityType,
    dateOfInformation,
    setDateOfInformation,
    updatedOn,
    setUpdatedOn,
  } = useContext(ReportContext);
  const { entityTypes } = useContext(ModelsContext);
  const { currentEntityId } = useContext(EntitiesContext);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation();

  const uploadFile = async (file) => {
    try {
      const payload = new FormData();
      payload.append('filename', getValues('entityName'));
      payload.append('file', file);
      await uploadLogo(payload);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (entityTypes.length) setEntityType(ENTITY_COMPANY);
  }, [entityTypes, setEntityType]);

  useEffect(() => {
    if (!report) return;

    const {
      name,
      periodsMenu,
      type,
      indicators: { scr },
      updatedOn,
      description: { scoreDescription },
    } = report;

    setValue('entityName', name);
    setValue('scoreDescription', scoreDescription);
    clearErrors('entityName');
    setPeriods(periodsMenu);
    setEntityType(type);

    if (!periodsMenu.includes(period)) setPeriod(periodsMenu[0]);
    const length = scr.data.length - 1;
    setValue('entityScore', scr.data[length].value);
    clearErrors('entityScore');

    setUpdatedOn(parseISO(updatedOn));
    setDateOfInformation(parseISO(periodsMenu[1]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handlePeriodChange = async (event) => {
    try {
      const date = event.target.value;
      setPeriod(date);
      const { data } = await getReport(currentEntityId, date);
      setReport(data);

      date === 'All'
        ? setDateOfInformation(parseISO(periods[1]))
        : setDateOfInformation(parseISO(date));
    } catch {
      toast.error('Download error');
    }
  };

  const validateFile = async (file) => {
    try {
      if (file.size > MAX_IMAGE_SIZE) throw new Error('The file has more that 5K bytes');

      if (file.width > FILE_WIDTH || file.height > FILE_HEIGHT)
        throw new Error('The file must have 32x32 pixels max');
    } catch (error) {
      throw error;
    }
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files.item(0);

      if (getFileExtension(file) !== FILE_PNG) throw new Error('Only accepts png files');

      const { width, height } = await getImageDimensions(file);
      file.width = width;
      file.height = height;

      await validateFile(file);

      setIsFileLoading(true);
      await uploadFile(file);

      // Reloads entities list
      clear();

      toast.success('Entity logo uploaded');
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsFileLoading(false);
    }
  };

  const isPrivateCompany = useIsPrivateCompany();
  const isSupplier = useIsSupplier();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Entity</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction={'column'}>
        <Grid item direction={'row'} container gap={2}>
          <Grid item>
            <TextField
              label='Available Dates'
              select
              value={period}
              onChange={handlePeriodChange}
              sx={{ width: '150px' }}
            >
              {periods.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField
              value={entityType}
              onChange={(event) => setEntityType(event.target.value)}
              select
              label='Type'
            >
              {entityTypes.map(({ id, name }) => (
                <MenuItem key={id} value={name}>
                  {t(`Editor.entity.entityTypes.${name}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container gap={2}>
            <Grid item xs>
              <Controller
                control={control}
                name='entityName'
                defaultValue=''
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <TextField
                      label='Entity Name'
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      error={!!errors[name]}
                      helperText={errors[name]?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container gap={2}>
            <Grid item xs={2}>
              <Controller
                control={control}
                name='entityScore'
                defaultValue={0}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <TextField
                      label='Score'
                      value={value}
                      onChange={onChange}
                      error={!!errors[name]}
                      helperText={errors[name]?.message}
                    />
                  );
                }}
              />
            </Grid>

            {(isPrivateCompany || isSupplier) && (
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name='scoreDescription'
                  defaultValue=''
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        label='Score Description'
                        value={value}
                        onChange={onChange}
                        error={!!errors[name]}
                        helperText={errors[name]?.message}
                      />
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='Date of information'
                inputFormat='dd/MM/yyyy'
                value={dateOfInformation}
                onChange={(date) => setDateOfInformation(date)}
                renderInput={(params) => <TextField {...params} />}
              />

              <DatePicker
                label='Updated on'
                inputFormat='dd/MM/yyyy'
                value={updatedOn}
                onChange={(date) => setUpdatedOn(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item>
          <label htmlFor='file-button'>
            <input
              id='file-button'
              name='file-button'
              type='file'
              accept={FILE_EXTENSION}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              disabled={!getValues('entityName')}
            />
            <Button
              variant='contained'
              size='large'
              disabled={!getValues('entityName')}
              component='span'
            >
              <span>Upload Logo (Format: .png | MaxSize: 5Kb | 32x32)</span>
              {isFileLoading && <Loader />}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default EditorEntity;
