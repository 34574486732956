import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomTable = ({ data, headers, translation }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header) =>
              header.isHidden ? null : (
                <TableCell key={header.id}>{t(`${translation}.${header.id}`)}</TableCell>
              )
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.id}>
                {headers.map((header) => {
                  const value = row[header.id];
                  return header.isHidden ? null : <TableCell key={header.id}>{value}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
