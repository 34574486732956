import { ApiClient, ApiClientUpload } from './ApiClient';
import { ITEMS_PAGE } from 'constants';

export const getEntitiesGrid = (limit = ITEMS_PAGE, offset = 0, filter = '') => {
  return ApiClient.get('/score/entities', {
    params: { limit, offset, filter },
  });
};

export const getReport = (entityId, period = 'All') => {
  const params = period === 'All' ? { entityId } : { entityId, period };

  return ApiClient.get('/score/report', {
    params,
  });
};

export const addReport = (payload) => ApiClient.post('/score/addReport', payload);

export const uploadLogo = (payload) => ApiClientUpload.post('/score/uploadLogo', payload);

export const deleteEntity = (payload) =>
  ApiClient.delete('/score/deleteEntity', { data: { payload } });

export const deleteEntityByDate = (payload) =>
  ApiClient.delete('/score/deleteEntityByDate', { data: { payload } });

export const getEntityPermissions = (entityId) =>
  ApiClient.get('/score/entityPermissions', { params: { entityId } });

export const getScoreAI = (payload) => ApiClient.post('/score/scoreAI', payload);
