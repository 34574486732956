import { Box, Tabs, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ClientsProvider } from 'providers/ClientsProvider';
import { UsersProvider } from 'providers/UsersProvider';

const commonStyle = {
  px: 0,
  py: 1,
  minHeight: 0,
};

export default function Accounts() {
  const location = useLocation();
  const [value, setValue] = useState('/accounts/clients');

  useEffect(() => {
    if (location.pathname === '/accounts') {
      setValue('/accounts/clients');
    } else {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ClientsProvider>
      <UsersProvider>
        <Box sx={{ height: '100vh', p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} sx={commonStyle}>
            <Tab
              label='Clients'
              component={Link}
              to='/accounts/clients'
              value='/accounts/clients'
              sx={commonStyle}
            />
            <Tab
              label='Users'
              component={Link}
              to='/accounts/users'
              value='/accounts/users'
              sx={commonStyle}
            />
          </Tabs>
          <Outlet />
        </Box>
      </UsersProvider>
    </ClientsProvider>
  );
}
