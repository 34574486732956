import { createContext, useState, useEffect, useCallback } from 'react';
import { parseOrgChartData } from 'components/Editor/utils';

export const ShareholdersChartContext = createContext();

export const ShareholdersChartProvider = ({ children }) => {
  const [levels, setLevels] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getParents = useCallback(
    (currentLevel) => {
      const validLevels = levels.filter(
        (level) => level.index === currentLevel - 1
      );

      let parents = [];

      validLevels.forEach((level) => {
        const validParents = nodes.filter((node) => node.levelId === level.id);
        parents.push(...validParents);
      });

      return parents;
    },
    [levels, nodes]
  );

  const handleClear = () => {
    setLevels([]);
    setNodes([]);
  };

  useEffect(() => {
    const data = parseOrgChartData(nodes);
    setChartData(data);
  }, [nodes]);

  const handleChangeNodeParent = (event, nodeId) => {
    const newNodes = structuredClone(nodes);
    const currentNodeIndex = newNodes.findIndex((node) => node.id === nodeId);
    newNodes[currentNodeIndex].parentId = event.target.value;
    setNodes(newNodes);
  };

  const defaultValue = {
    levels,
    setLevels,
    nodes,
    setNodes,
    getParents,
    chartData,
    handleChangeNodeParent,
    handleClear,
  };

  return (
    <ShareholdersChartContext.Provider value={defaultValue}>
      {children}
    </ShareholdersChartContext.Provider>
  );
};
