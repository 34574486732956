import React, { useContext, useEffect } from 'react';
import { Accordion, AccordionSummary, Typography, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from 'components/Table';
import { ReportContext } from 'providers/ReportProvider';
import { ModelsContext } from 'providers/ModelsProvider';
import { ENTITY_COMPANY, ENTITY_BANK, ENTITY_PRIVATE_COMPANY, TABLE_HEADERS } from 'constants';
import { parseIndicator } from 'utils';
import { ENTITY_SUPPLIER } from 'constants';

const EditorRatios = () => {
  const { report, period, entityType, ratios, setRatios } = useContext(ReportContext);
  const { ratiosItems } = useContext(ModelsContext);

  const handleOnChange = (event, id) => {
    const temp = [...ratios];
    temp[id].value = event.target.value;
    setRatios(temp);
  };

  const parseRatios = (values, entityType, period) => {
    let keys = [];
    const indicators = [];

    if (entityType === ENTITY_COMPANY) {
      keys = ratiosItems.company.map((item) => item.code);
    } else if (entityType === ENTITY_BANK) {
      keys = ratiosItems.bank.map((item) => item.code);
    } else if (entityType === ENTITY_PRIVATE_COMPANY) {
      keys = ratiosItems.privateCompany.map((item) => item.code);
    } else if (entityType === ENTITY_SUPPLIER) {
      keys = ratiosItems.supplier.map((item) => item.code);
    }

    keys.forEach((key, index) => {
      const indicator = values[key];

      indicators.push({
        id: index,
        field: indicator.metadata.name,
        value: parseIndicator(indicator, period),
        code: key,
        indicatorId: indicator.metadata.id,
      });
    });
    return indicators;
  };

  useEffect(() => {
    if (!report) return;
    const { indicators, type } = report;
    const res = parseRatios(indicators, type, period);
    setRatios(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, period]);

  useEffect(() => {
    if (report) return;

    if (entityType === ENTITY_BANK) {
      setRatios(ratiosItems.bank);
    }

    if (entityType === ENTITY_COMPANY) {
      setRatios(ratiosItems.company);
    }

    if (entityType === ENTITY_PRIVATE_COMPANY) {
      setRatios(ratiosItems.privateCompany);
    }

    if (entityType === ENTITY_SUPPLIER) {
      setRatios(ratiosItems.supplier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, report]);

  useEffect(() => {
    if (entityType === ENTITY_COMPANY) setRatios(ratiosItems.company);
  }, [entityType, ratiosItems, setRatios]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Ratios</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2}>
        {ratios && <Table headers={TABLE_HEADERS} rows={ratios} onChange={handleOnChange} />}
      </Grid>
    </Accordion>
  );
};

export default EditorRatios;
