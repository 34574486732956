import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';

export const Menu = ({ onAdd, onReload }) => {
  const { t } = useTranslation();

  const handleReloadClick = () => {
    onReload();
  };

  const handleAddClick = () => {
    onAdd();
  };

  return (
    <Box>
      <Tooltip title={t('Toolbar.reload')} placement='top'>
        <IconButton onClick={handleReloadClick}>
          <ReplayIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('Toolbar.add')} placement='top'>
        <IconButton onClick={handleAddClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
