import { useContext, useState } from 'react';
import CustomModal from 'components/CustomModal';
import { getRandomPassword } from 'utils';
import { Form, ButtonsGroupWrapper, ButtonLoader } from 'styles/common';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { addUser } from 'services/ApiUsers';
import LoaderIcon from 'assets/loaders/loader.svg';
import { Button, ButtonGroup, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { UsersContext } from 'providers/UsersProvider';

const AddUser = () => {
  const { isOpenAddUserModal, setIsOpenAddUserModal, roles, clientCodes, setIsReloadUsers } =
    useContext(UsersContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const [email, setEmail] = useState('');
  const [clientCode, setClientCode] = useState('ccc');
  const [active, setActive] = useState(1);
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState('idle');

  const activeOptions = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },
  ];

  const clearForm = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setRole(roles[0].role);
    setClientCode(clientCodes[0].code);
    setActive(activeOptions[0].value);
    setErrors([]);
  };

  const handleClose = () => {
    clearForm();
    setIsOpenAddUserModal(false);
  };

  const handleCreatePasswordClick = () => {
    const newRandomPassword = getRandomPassword();
    setPassword(newRandomPassword);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    setEmail(email);
    const [username] = email.split('@');
    setUsername(username);
  };

  const handleClientCodeChange = (event) => {
    setClientCode(event.target.value);
  };

  const handleActiveChange = (event) => {
    setActive(event.target.value);
  };

  const handleAddClick = async () => {
    try {
      const payload = {
        email,
        username,
        role,
        clientCode,
        password,
        active,
      };

      setStatus('pending');
      const isValidPayload = await isValidateAddUserForm(payload);
      if (!isValidPayload) return;
      await addUser(payload);
      setIsOpenAddUserModal(false);
      setIsReloadUsers(true);
      clearForm();
      toast.success(t('AddUser.success'));
    } catch (error) {
      console.error(`HandleAddClick error: ${error}`);
      setIsOpenAddUserModal(false);
      clearForm();
      toast.error(t('Error.Create'));
    } finally {
      setStatus('idle');
    }
  };

  const isValidateAddUserForm = async (payload) => {
    const schema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      username: Joi.string().required(),
      role: Joi.string().required(),
      clientCode: Joi.string().required(),
      password: Joi.string().required().min(16),
      active: Joi.number().min(0).max(1),
    });

    const res = schema.validate(payload, {
      abortEarly: false,
    });

    const errorMessages = {};

    res.error?.details.forEach((error) => (errorMessages[error.path] = error.message));

    setErrors(errorMessages);

    if (res.error) return false;
    return true;
  };

  const ModalAddUserBody = (
    <Form>
      <div>Email</div>
      <TextField
        value={email}
        onChange={handleEmailChange}
        error={errors.email ? true : false}
        helperText={errors.email ? 'Email error' : ''}
      />

      <div>Username</div>
      <TextField
        value={username}
        onChange={handleUsernameChange}
        error={errors.username ? true : false}
        helperText={errors.username ? 'Username error' : ''}
      />

      <div>Role</div>
      <TextField value={role} onChange={handleRoleChange} select>
        {roles.map((role, index) => (
          <MenuItem key={index} value={role.role}>
            {role.role}
          </MenuItem>
        ))}
      </TextField>

      <div>Client</div>
      <TextField value={clientCode} onChange={handleClientCodeChange} select>
        {clientCodes.map((clientCode, index) => (
          <MenuItem key={`clientCode-${index}`} value={clientCode.code}>
            {clientCode.name}
          </MenuItem>
        ))}
      </TextField>

      <div>Password</div>
      <TextField
        type='password'
        value={password}
        error={errors.password ? true : false}
        helperText={errors.password ? 'Password error' : ''}
      />
      <Button onClick={handleCreatePasswordClick} variant='contained' color='primary'>
        Create password
      </Button>

      <div>Active</div>
      <TextField value={active} onChange={handleActiveChange} select>
        {activeOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <ButtonsGroupWrapper>
        <ButtonGroup>
          <Button variant='contained' color='primary' onClick={handleAddClick}>
            {status === 'pending' ? <ButtonLoader src={LoaderIcon} /> : 'Add'}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ButtonGroup>
      </ButtonsGroupWrapper>
    </Form>
  );

  return (
    <CustomModal open={isOpenAddUserModal} onClose={handleClose} title='Add user'>
      {ModalAddUserBody}
    </CustomModal>
  );
};

export default AddUser;
