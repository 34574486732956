import React, { useState, useContext } from 'react';
import { ButtonsGroupWrapper, ButtonLoader } from 'styles/common';
import LoaderIcon from 'assets/loaders/loader.svg';
import CustomModal from 'components/CustomModal';
import { deleteUser } from 'services/ApiUsers';
import { Button, ButtonGroup } from '@mui/material';
import { toast } from 'react-toastify';
import { UsersContext } from 'providers/UsersProvider';

const DeleteUserModal = () => {
  const [status, setStatus] = useState('idle');
  const { isOpenDeleteUserModal, setIsOpenDeleteUserModal, setIsReloadUsers, currentUser } =
    useContext(UsersContext);

  const handleClose = () => {
    setIsOpenDeleteUserModal(false);
  };

  const handleDeleteClick = async () => {
    try {
      setStatus('pending');
      await deleteUser({ id: currentUser.id });
      setIsReloadUsers(true);
      toast.success('User deleted');
    } catch (error) {
      toast.error('Error deleting user');
      console.error(`handleDeleteUserClick.error: ${error}`);
    } finally {
      setStatus('idle');
      setIsOpenDeleteUserModal(false);
    }
  };

  const ModalBody = (
    <>
      <p>Confirm delete user</p>
      <ButtonsGroupWrapper>
        <ButtonGroup>
          <Button variant='contained' color='primary' onClick={handleDeleteClick}>
            {status === 'pending' ? <ButtonLoader src={LoaderIcon} /> : 'Delete'}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ButtonGroup>
      </ButtonsGroupWrapper>
    </>
  );

  return (
    <CustomModal
      open={isOpenDeleteUserModal}
      OnClose={handleClose}
      title={`Delete user: ${currentUser.email}`}
    >
      {ModalBody}
    </CustomModal>
  );
};

export default DeleteUserModal;
