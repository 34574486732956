import React, { useContext, useMemo } from 'react';
import { AppContainer } from './App.styles';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesList from 'router/routes';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { lightBlue, red, yellow, blue, green, grey } from '@mui/material/colors';
import { ThemeUIContext } from 'providers/ThemeUIProvider';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { mode } = useContext(ThemeUIContext);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            main: lightBlue[900],
          },
          blue: blue[500],
          red: red[500],
          yellow: yellow[500],
          green: green[500],
          disabled: grey[50],
        },
      }),
    [mode]
  );

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SCThemeProvider theme={theme}>
          <AppContainer>
            <ToastContainer />
            <RoutesList />
          </AppContainer>
        </SCThemeProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
