import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ClientFormValidation from './ClientFormValidation';
import { TextField, Box, ButtonGroup, Button, MenuItem } from '@mui/material';
import { camelCase, removeSpaces } from 'utils';
import { useState } from 'react';
import { ButtonLoader } from 'styles/common';
import LoaderIcon from 'assets/loaders/loader.svg';
import { toast } from 'react-toastify';
import { addClient } from 'services/ApiClients';

const ClientForm = ({ defaultValues, countries, onClose, activeValues, successCallback }) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ClientFormValidation),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    getValues,
    trigger,
  } = methods;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const payload = {
        ...data,
        active: data.active === '1' ? 1 : 0,
      };
      await addClient(payload);
      toast.success('Client Added!');

      successCallback();
    } catch (error) {
      toast.error('Cannot create client');
    }
    setIsLoading(false);
    onClose();
  };

  const getClientCode = () => {
    const [name] = getValues(['name']);
    if (!name) return;
    const code = name.substring(0, 3).toLowerCase();
    setValue('code', code);
  };

  const getClientShortname = () => {
    const [name] = getValues(['name']);
    if (!name) return;
    const shortname = removeSpaces(camelCase(name));
    setValue('shortname', shortname);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <TextField
              label='Name'
              variant='outlined'
              {...field}
              fullWidth
              onBlur={() => {
                getClientCode();
                getClientShortname();
                trigger();
              }}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          )}
          defaultValue=''
        />

        <Controller
          name='shortname'
          control={control}
          render={({ field }) => (
            <TextField
              label='ShortName'
              variant='outlined'
              {...field}
              fullWidth
              error={!!errors.shortname}
              helperText={errors?.shortname?.message}
            />
          )}
          defaultValue=''
        />

        <Controller
          name='code'
          control={control}
          render={({ field }) => (
            <TextField
              label='Code'
              variant='outlined'
              {...field}
              fullWidth
              error={!!errors.code}
              helperText={errors?.code?.message}
            />
          )}
          defaultValue=''
        />

        <Controller
          name='country'
          control={control}
          render={({ field }) => (
            <TextField label='Country' variant='outlined' fullWidth select {...field}>
              {countries.map((item) => (
                <MenuItem
                  key={item.code}
                  value={item.code}
                >{`${item.name} (${item.code})`}</MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name='active'
          control={control}
          render={({ field }) => (
            <TextField label='Active' variant='outlined' fullWidth select {...field}>
              {activeValues.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <ButtonGroup sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button variant='contained' color='primary' type='submit' disabled={!isValid}>
            {isLoading ? <ButtonLoader src={LoaderIcon} /> : 'Add'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonGroup>
      </Box>
    </form>
  );
};

export default ClientForm;
