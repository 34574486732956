import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  MenuItem,
  Grid,
  Divider,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from './TextEditor';
import { ReportContext } from 'providers/ReportProvider';
import { ModelsContext } from 'providers/ModelsProvider';
import useIsPrivateCompany from 'hooks/useIsPrivateCompany';
import useIsSupplier from 'hooks/useIsSupplier';

const EditorGeneralInfo = () => {
  const {
    report,
    observation,
    setObservation,
    status,
    setStatus,
    margin,
    setMargin,
    rating,
    setRating,
    sp,
    setSP,
    moodys,
    setMoodys,
    fitch,
    setFitch,
    regionId,
    setRegionId,
    countryId,
    setCountryId,
    marketId,
    setMarketId,
    profileId,
    setProfileId,
    sectorId,
    setSectorId,
    initialDescription,
    setInitialDescription,
    description,
    setDescription,
    outlook,
    setOutlook,
  } = useContext(ReportContext);
  const { regions, countries, markets, profiles, sectors } = useContext(ModelsContext);

  const isPrivateCompany = useIsPrivateCompany();
  const isSupplier = useIsSupplier();

  const handleInputChange = (event, setter) => setter(event.target.value);

  useEffect(() => {
    if (!report) return;
    const { description } = report;
    setInitialDescription(description.text);
    setRegionId(description.regionId);
    setCountryId(description.countryId);
    setMarketId(description.marketId);
    setProfileId(description.profileId);
    setSectorId(description.sectorId);
    setObservation(description.observation);
    setStatus(description.status);
    setMargin(description.margin);
    setRating(description.rating);
    setSP(description.sp);
    setMoodys(description.moodys);
    setFitch(description.fitch);
    setOutlook(description.outlook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    if (regions.length) setRegionId(regions[0].id);
    if (countries.length) setCountryId(countries[0].id);
    if (markets.length) setMarketId(markets[0].id);
    if (profiles.length) setProfileId(profiles[0].id);
    if (sectors.length) setSectorId(sectors[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions, countries, markets, profiles, sectors]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>General Information</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction='column'>
        <Typography>Description</Typography>
        <TextEditor
          initialText={initialDescription}
          editorState={description}
          setEditorState={setDescription}
        />

        <Grid item container gap={2}>
          <Grid item xs>
            <TextField
              select
              fullWidth
              label='Region'
              value={regionId}
              onChange={(event) => setRegionId(event.target.value)}
            >
              {regions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs>
            <TextField
              select
              fullWidth
              label='Country'
              value={countryId}
              onChange={(event) => setCountryId(event.target.value)}
            >
              {countries.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container gap={2}>
          <Grid item xs>
            <TextField
              select
              fullWidth
              label='Market'
              value={marketId}
              onChange={(event) => setMarketId(event.target.value)}
            >
              {markets.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs>
            <TextField
              select
              fullWidth
              label='Profile'
              value={profileId}
              onChange={(event) => setProfileId(event.target.value)}
            >
              {profiles.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs>
            <TextField
              select
              fullWidth
              label='Sector'
              value={sectorId}
              onChange={(event) => setSectorId(event.target.value)}
            >
              {sectors.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {!(isPrivateCompany || isSupplier) && (
          <>
            <Grid item container gap={2}>
              <Grid item xs>
                <TextField
                  label='Observation'
                  fullWidth
                  value={observation}
                  onChange={(event) => handleInputChange(event, setObservation)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  label='Status'
                  fullWidth
                  value={status}
                  onChange={(event) => handleInputChange(event, setStatus)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  label='Margin'
                  fullWidth
                  value={margin}
                  onChange={(event) => handleInputChange(event, setMargin)}
                />
              </Grid>
            </Grid>

            <Typography>Notation</Typography>
            <Grid item container gap={2}>
              <Grid item xs>
                <TextField
                  label='Rating'
                  fullWidth
                  value={rating}
                  onChange={(event) => handleInputChange(event, setRating)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  label={'S&P'}
                  value={sp}
                  onChange={(event) => handleInputChange(event, setSP)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  label="Moody's"
                  value={moodys}
                  onChange={(event) => handleInputChange(event, setMoodys)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  label='Fitch'
                  value={fitch}
                  onChange={(event) => handleInputChange(event, setFitch)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  label='Outlook'
                  value={outlook}
                  onChange={(event) => handleInputChange(event, setOutlook)}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Accordion>
  );
};

export default EditorGeneralInfo;
