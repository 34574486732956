import { Accordion, AccordionSummary, Box, Divider, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect } from 'react';
import { ReportContext } from 'providers/ReportProvider';

const EditorResultsSupplier = () => {
  const {
    report,
    commentsResultsSupplier,
    setCommentsResultsSupplier,
    sizeContract,
    setSizeContract,
  } = useContext(ReportContext);

  useEffect(() => {
    if (!report) return;

    const {
      description: { sizeContract, commentsResultsSupplier },
    } = report;

    setSizeContract(sizeContract);
    setCommentsResultsSupplier(commentsResultsSupplier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Results</Typography>
      </AccordionSummary>

      <Divider />

      <Box sx={{ display: 'flex', p: 2, flexDirection: 'column', gap: 2 }}>
        <TextField
          label='Comments'
          fullWidth
          value={commentsResultsSupplier}
          onChange={(event) => {
            setCommentsResultsSupplier(event.target.value);
          }}
          multiline
          rows={4}
        />

        <TextField
          label='Size'
          fullWidth
          value={sizeContract}
          onChange={(event) => {
            setSizeContract(event.target.value);
          }}
        />
      </Box>
    </Accordion>
  );
};

export default EditorResultsSupplier;
