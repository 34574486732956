import React, { useContext } from 'react';
import EditorForm from './EditorForm';
import { EditorLayout } from './Editor.styles';
import LeftBar from 'components/LeftBar';
import { EntitiesContext } from 'providers/EntitiesProvider';

const Editor = () => {
  const { showNav, setCurrentPage } = useContext(EntitiesContext);

  const onPageChange = (pageIndex) => {
    const { selected } = pageIndex;
    setCurrentPage(selected);
  };

  return (
    <EditorLayout showNav={showNav}>
      <LeftBar onPageChange={onPageChange} />
      <EditorForm />
    </EditorLayout>
  );
};

export default Editor;
