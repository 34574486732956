import { useContext, useState } from 'react';
import { Accordion, AccordionSummary, Button, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReportContext } from 'providers/ReportProvider';
import { toast } from 'react-toastify';
import { getScoreAI } from 'services/ApiScore';
import Knob from 'components/Knob';
import { ENTITY_SUPPLIER } from 'constants';

const EditorScoreAI = () => {
  const [scoreAI, setScoreAI] = useState('');
  const { entityType, ratios } = useContext(ReportContext);
  const [isDisabled, setIsDisabled] = useState(false);

  const isNotAvailable = entityType === ENTITY_SUPPLIER;

  const handleClick = async () => {
    try {
      const payload = {
        entityType,
        values: ratios,
      };
      setIsDisabled(true);
      const {
        data: { score },
      } = await getScoreAI(payload);
      setScoreAI(score);
      setIsDisabled(false);
    } catch (error) {
      toast.error('Cannot calculate score');
      setIsDisabled(false);
    }
  };

  const renderContent = () => {
    if (isNotAvailable) return <Typography p={2}>Currently not available for Suppliers</Typography>;

    return (
      <Grid container padding={2} gap={2} direction={'column'}>
        <Grid item xs>
          <Button variant='contained' disabled={isDisabled} onClick={handleClick}>
            Get Score
          </Button>
        </Grid>

        <Knob value={scoreAI} style={{ justifySelf: 'center' }} />
      </Grid>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Experimental: Score A.I.</Typography>
      </AccordionSummary>

      {renderContent()}
    </Accordion>
  );
};

export default EditorScoreAI;
