import React from 'react';
import { TextField, Box } from '@mui/material';

const Table = ({ headers, rows, onChange, parentId }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <table cellSpacing='0'>
        <thead>
          <tr>
            {headers.map((item) => (
              <th
                key={item}
                style={{ border: '1px solid gray', padding: '1rem 0' }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((item) => (
            <tr key={item.id}>
              <td style={{ padding: '1rem', border: '1px solid gray' }}>
                {item.field}
              </td>
              <td
                style={{
                  minWidth: '200px',
                  textAlign: 'right',
                  border: '1px solid gray',
                }}
              >
                <TextField
                  value={item.value}
                  inputProps={{
                    style: { textAlign: 'right' },
                  }}
                  onChange={(event) => onChange(event, item.id, parentId)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Table;
