import { useContext } from 'react';
import { EntitiesContext } from 'providers/EntitiesProvider';

export const useClearFilter = () => {
  const { filter, setFilter, setCurrentPage } = useContext(EntitiesContext);

  const clear = () => {
    const tempFilter = JSON.parse(JSON.stringify(filter));
    tempFilter.search = '';
    setFilter(tempFilter);
    setCurrentPage(0);
  };

  return { clear };
};
