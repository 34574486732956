import { useTranslation } from 'react-i18next';
import { Box, ButtonGroup, IconButton, Tooltip, Typography } from '@mui/material';
import { UsersContext } from 'providers/UsersProvider';
import { useContext } from 'react';
import Search from './Search';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

export const Menu = ({ onAdd, onReload, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const { currentUser, filter, setFilter, usersCount } = useContext(UsersContext);
  const isDisabled = !currentUser?.id;

  const handleClearFilterClick = () => {
    setFilter('');
  };

  const handleOnChangeSearch = (event) => {
    const filter = event.target.value;
    setFilter(filter);
  };

  return (
    <Box sx={{ display: 'flex', marginTop: '0.5rem', alignItems: 'center', gap: 1, mb: 2 }}>
      <Typography>{`Total users (${usersCount})`}</Typography>
      <ButtonGroup>
        <Tooltip title={t('Toolbar.add')} placement='top'>
          <span>
            <IconButton onClick={onAdd}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Toolbar.reload')} placement='top'>
          <span>
            <IconButton onClick={onReload}>
              <ReplayIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Toolbar.edit')} placement='top'>
          <span>
            <IconButton onClick={onEdit} disabled={isDisabled}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Toolbar.delete')} placement='top'>
          <span>
            <IconButton onClick={onDelete} disabled={isDisabled}>
              <ClearIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Search
          label={t('Search.Username')}
          handleOnChangeSearch={handleOnChangeSearch}
          handleClearFilterClick={handleClearFilterClick}
          value={filter}
        />
      </ButtonGroup>
    </Box>
  );
};
