import { useState } from 'react';
import Logo from 'components/Logo';
import Footer from 'components/Footer';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { parseRecoverToken } from 'utils';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';

const Login = () => {
  const { t } = useTranslation();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const location = useLocation();
  const recoverToken = parseRecoverToken(location.search);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        direction='column'
        padding={2}
        gap={2}
        sx={{
          display: 'flex',
          maxWidth: '325px',
          border: '1px solid',
          borderColor: 'text.disabled',
          textAlign: 'center',
        }}
      >
        <Logo />
        <LoginForm
          isForgotPassword={isForgotPassword}
          setIsForgotPassword={setIsForgotPassword}
          recoverToken={recoverToken}
        />

        {!isForgotPassword && !recoverToken && (
          <Button onClick={() => setIsForgotPassword(true)}>{t('Login.Forgot')}</Button>
        )}
      </Grid>

      <Footer />
    </Box>
  );
};

export default Login;
