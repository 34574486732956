import { useState } from 'react';
import { Loader } from 'styles/common';
import LoaderIcon from 'assets/loaders/loader.svg';
import { Button, TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUserSettings } from 'services/ApiUser';
import i18next from 'i18next';
import { login, recoverPassword, resetPassword } from 'services/AuthApi';
import Joi from 'joi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginForm = ({ isForgotPassword, setIsForgotPassword, recoverToken }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState('idle');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const navigate = useNavigate();

  async function validateForm(params) {
    try {
      const { email, password } = params;
      const schema = Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
        password: Joi.string().required(),
      });

      const result = await schema.validateAsync({ email, password }, { abortEarly: false });
      if (result.email && result.password) return true;
      return false;
    } catch (error) {
      throw error;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus('pending');

    try {
      const isValidForm = await validateForm({ email, password });

      if (!isValidForm) throw new Error('Not Valid');

      const { data } = await login({ email, password });
      const { id: userId, token, username, role, last, privileges } = data;
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('role', role);
      localStorage.setItem('last', last);
      localStorage.setItem('privileges', JSON.stringify(privileges));

      const { data: settings } = await getUserSettings(userId);

      localStorage.setItem('userSettings', JSON.stringify(settings));
      i18next.changeLanguage(settings.lang);

      setStatus('resolved');
      const pathname = '/editor';
      navigate(pathname);
    } catch (error) {
      setStatus('rejected');
      toast.error(t('Error.Invalid credentials'));
    }
  };

  const handleRecoverPasswordClick = async (event) => {
    event.preventDefault();
    setStatus('pending');

    try {
      await recoverPassword({ email });
      setStatus('resolved');

      toast.success('Recover email sent');
    } catch (error) {
      setStatus('rejected');

      toast.error('Recover error');
    } finally {
      setIsForgotPassword(false);
    }
  };

  const handleCancelRecoverClick = (event) => {
    event.preventDefault();
    setIsForgotPassword(false);
  };

  const handleResetPasswordClick = async (event) => {
    try {
      event.preventDefault();
      setStatus('pending');
      await resetPassword({ email, password, token: recoverToken });
      setStatus('resolved');
      toast.error('Login with your new password');
    } catch (error) {
      setStatus('rejected');
      toast.error('Recover error');
    }
    navigate('/');
  };

  const handleCancelResetClick = (event) => {
    event.preventDefault();
    navigate('/');
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label={t('Login.Email')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      {!isForgotPassword ? (
        <Grid container gap={2}>
          <TextField
            type='password'
            label={t('Login.Password')}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
          />

          {recoverToken && (
            <TextField
              type='password'
              label={t('Login.RepeatPassword')}
              value={repeatPassword}
              onChange={(event) => setRepeatPassword(event.target.value)}
              fullWidth
            />
          )}

          {!recoverToken ? (
            <Button type='submit' variant='contained' fullWidth sx={{ minHeight: '36px' }}>
              {status === 'pending' ? <Loader src={LoaderIcon} /> : t('Login.Button')}
            </Button>
          ) : (
            <>
              <Button onClick={handleResetPasswordClick} variant='contained' fullWidth>
                {status === 'pending' ? <Loader src={LoaderIcon} /> : t('Login.ResetPassword')}
              </Button>

              <Button
                onClick={handleCancelResetClick}
                disabled={status === 'pending' ? true : false}
                variant='contained'
                fullWidth
              >
                {t('Common.Cancel')}
              </Button>
            </>
          )}
        </Grid>
      ) : (
        <Grid container gap={2}>
          <Button onClick={handleRecoverPasswordClick} variant='contained' fullWidth>
            {status === 'pending' ? <Loader src={LoaderIcon} /> : t('Login.Recover')}
          </Button>

          <Button
            onClick={handleCancelRecoverClick}
            disabled={status === 'pending' ? true : false}
            variant='contained'
            fullWidth
          >
            {t('Common.Cancel')}
          </Button>
        </Grid>
      )}
    </form>
  );
};

export default LoginForm;
