import styled from 'styled-components';

export const PaginatorContainer = styled.div`
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    margin: 0 0 0.5rem 0;
  }

  .pagination li {
    display: inline-block;
    padding: 0.5rem;
  }

  .pagination a {
    cursor: pointer;
  }

  .pagination li:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: white;
  }

  .pagination li a:focus {
    outline: none;
  }

  .active {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: none;
    color: white;
  }

  .active-link {
    border: none;
  }

  .disabled {
    background-color: ${(props) => props.theme.palette.action.disabled};
  }
`;
