import React from 'react';
import trustLogo from 'assets/logos/trust32.png';
import iserLogo from 'assets/logos/iser32.png';
import { useTranslation } from 'react-i18next';
import { CardMedia, Grid, Box } from '@mui/material';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      direction='column'
      gap={2}
      padding={2}
    >
      <Grid item>
        <span>{t('Footer.Copyright')}</span>
      </Grid>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Grid item container direction='column' alignItems='center'>
          <CardMedia
            image={trustLogo}
            alt='Tisas'
            component='img'
            sx={{ width: '32px', height: '32px' }}
          />
          <div>{t('Footer.Tisas')}</div>
        </Grid>

        <Grid item container direction='column' alignItems='center'>
          <CardMedia
            image={iserLogo}
            alt='Iser'
            component='img'
            sx={{ width: '32px', height: '32px' }}
          />
          <div>{t('Footer.ILBS')}</div>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Footer;
