import React, { useRef, useEffect } from 'react';
import { useTheme } from '@mui/system';
import { scoreColor } from 'utils';

const Knob = ({ value, ...props }) => {
  const canvasRef = useRef(null);
  const theme = useTheme();

  const color = scoreColor(value, theme);

  const xPos = 75;
  const YPos = 75;
  const radius = 75;
  const fontSize = 30;
  const MAX_VALUE = 5.0;
  const size = 150;
  const lineWidth = 30;
  const valueRadiusFactor = 0.8;

  useEffect(() => {
    const initialRotation = 1.5 * Math.PI;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.style.height = `${size}px`;
    canvas.style.width = `${size}px`;
    canvas.width = Math.floor(size);
    canvas.height = Math.floor(size);

    const getValueArc = () =>
      (2 * value * Math.PI) / MAX_VALUE + initialRotation;

    const offsetTextX = () => {
      const factor = value.toString().includes('.') ? 0.75 : 0.25;
      return factor * fontSize;
    };

    const offsetTextY = () => 0.3 * fontSize;

    // Background
    ctx.beginPath();
    ctx.arc(xPos, YPos, radius, 0, 2 * Math.PI);
    ctx.fillStyle = theme.palette.background.default;
    ctx.fill();
    ctx.closePath();

    // Value Arc
    ctx.beginPath();
    ctx.arc(
      xPos,
      YPos,
      valueRadiusFactor * radius,
      initialRotation,
      getValueArc()
    );
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.closePath();

    // Label
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = theme.palette.text.primary;
    ctx.fillText(value, xPos - offsetTextX(), YPos + offsetTextY());
  }, [value, theme, color]);

  return <canvas ref={canvasRef} {...props} />;
};

export default Knob;
