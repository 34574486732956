import { ModalWrapper, ModalHeader, Close } from './Modal.styles';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Modal } from '@mui/material';

const CustomModal = ({ title, onClose, open, children }) => {
  const modalContent = (
    <ModalWrapper>
      <ModalHeader>
        <span>{title}</span>
        <Close onClick={onClose}>
          <CloseIcon />
        </Close>
      </ModalHeader>

      <Divider />

      {children}
    </ModalWrapper>
  );

  return (
    <Modal open={open} onClose={onClose}>
      {modalContent}
    </Modal>
  );
};

export default CustomModal;
