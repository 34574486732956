import React, { useState, useContext, useEffect } from 'react';
import { Form, ButtonsGroupWrapper, ButtonLoader } from 'styles/common';
import LoaderIcon from 'assets/loaders/loader.svg';
import CustomModal from 'components/CustomModal';
import { updateUser } from 'services/ApiUsers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { Button, ButtonGroup, MenuItem, TextField } from '@mui/material';
import { UsersContext } from 'providers/UsersProvider';

const EditUserModal = () => {
  const {
    isOpenEditUserModal,
    setIsOpenEditUserModal,
    roles,
    clientCodes,
    setIsReloadUsers,
    currentUser,
  } = useContext(UsersContext);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('user');
  const [email, setEmail] = useState('');
  const [clientCode, setClientCode] = useState('ccc');
  const [active, setActive] = useState(1);
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    setEmail(currentUser.email);
    setUsername(currentUser.username);
    setClientCode(currentUser.client_code);
    setActive(currentUser.active);
    setRole(currentUser.role);
  }, [isOpenEditUserModal, currentUser]);

  const activeOptions = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },
  ];

  const clearForm = () => {
    setUsername('');
    setEmail('');
    setRole(roles[0].role);
    setClientCode(clientCodes[0].code);
    setActive(activeOptions[0].value);
    setErrors([]);
  };

  const handleClose = () => {
    clearForm();
    setIsOpenEditUserModal(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleClientCodeChange = (event) => {
    setClientCode(event.target.value);
  };

  const handleActiveChange = (event) => {
    setActive(event.target.value);
  };

  const handleAddClick = async () => {
    try {
      const payload = {
        email,
        username,
        role,
        clientCode,
        active,
      };
      setStatus('pending');
      const isValidPayload = await isValidateUserForm(payload);
      if (!isValidPayload) return;
      await updateUser(payload);
      setIsOpenEditUserModal(false);
      setIsReloadUsers(true);
      clearForm();
      toast.success(t('EditUser.success'));
    } catch (error) {
      console.error(`HandleEditClick error: ${error}`);
      setIsOpenEditUserModal(false);
      clearForm();
      toast.error(t('Error.Edit'));
    } finally {
      setStatus('idle');
    }
  };

  const isValidateUserForm = async (payload) => {
    const schema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      username: Joi.string().required(),
      role: Joi.string().required(),
      clientCode: Joi.string().required(),
      active: Joi.number().min(0).max(1),
    });

    const res = schema.validate(payload, {
      abortEarly: false,
    });

    const errorMessages = {};

    res.error?.details.forEach((error) => (errorMessages[error.path] = error.message));

    setErrors(errorMessages);

    if (res.error) return false;
    return true;
  };

  const ModalUserBody = (
    <Form>
      <div>Email</div>
      <TextField defaultValue={email} disabled />

      <div>Username</div>
      <TextField
        value={username}
        onChange={handleUsernameChange}
        error={errors.username ? true : false}
        helperText={errors.username ? 'Username error' : ''}
      />

      <div>Role</div>
      <TextField value={role} onChange={handleRoleChange} select>
        {roles.map((role, index) => (
          <MenuItem key={index} value={role.role}>
            {role.role}
          </MenuItem>
        ))}
      </TextField>

      <div>Client</div>
      <TextField value={clientCode} onChange={handleClientCodeChange} select>
        {clientCodes.map((clientCode, index) => (
          <MenuItem key={index} value={clientCode.code}>
            {clientCode.name}
          </MenuItem>
        ))}
      </TextField>

      <div>Active</div>
      <TextField value={active} onChange={handleActiveChange} select>
        {activeOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <ButtonsGroupWrapper>
        <ButtonGroup>
          <Button variant='contained' color='primary' onClick={handleAddClick}>
            {status === 'pending' ? <ButtonLoader src={LoaderIcon} /> : 'Edit'}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ButtonGroup>
      </ButtonsGroupWrapper>
    </Form>
  );

  return (
    <CustomModal open={isOpenEditUserModal} onClose={handleClose} title='Edit user'>
      {ModalUserBody}
    </CustomModal>
  );
};

export default EditUserModal;
