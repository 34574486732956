import { useEffect, useContext } from 'react';
import { Layout } from './Editor.styles';
import { ThemeUIContext } from 'providers/ThemeUIProvider';
import Editor from 'components/Editor/Editor';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportProvider } from 'providers/ReportProvider';
import { ModelsProvider } from 'providers/ModelsProvider';
import { EntitiesProvider } from 'providers/EntitiesProvider';
import { ShareholdersChartProvider } from 'providers/ShareholdersChartProvider';

const EditorPage = () => {
  const { setMode } = useContext(ThemeUIContext);

  const validationSchema = Yup.object().shape({
    entityName: Yup.string().required('Field required'),
    entityScore: Yup.number()
      .typeError('Number required')
      .required('Field required')
      .min(0, 'Minimum is 0')
      .max(5, 'Max is 5'),
  });

  const methods = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (localStorage.getItem('userSettings')) {
      const { theme } = JSON.parse(localStorage.getItem('userSettings'));
      return setMode(theme);
    }

    setMode('dark');
  }, [setMode]);

  return (
    <ModelsProvider>
      <EntitiesProvider>
        <ReportProvider>
          <ShareholdersChartProvider>
            <FormProvider {...methods}>
              <Layout>
                <Editor />
              </Layout>
            </FormProvider>
          </ShareholdersChartProvider>
        </ReportProvider>
      </EntitiesProvider>
    </ModelsProvider>
  );
};

export default EditorPage;
