export const parseOrgChartData = (nodes) => {
  const data = nodes.map((node) => {
    const dataPoint = [];
    const { parentId, title, levelIndex } = node;

    if (parentId && title) {
      const parentNode = nodes.find((node) => node.id === parentId);
      dataPoint.push(parentNode.title, node.title);
      return dataPoint;
    } else if (title && levelIndex === 0) {
      dataPoint.push(title, null);
    }

    return dataPoint;
  });

  return data;
};
