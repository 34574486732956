export const ITEMS_PAGE = 12;
export const MIN_SEARCH_CHARS = 3;
export const EMPTY_SYMBOL = '-';
export const LOCALSTORAGE_ITEMS = [
  'username',
  'token',
  'role',
  'last',
  'userSettings',
  'i18nextLng',
  'privileges',
];
export const ENTITY_COMPANY = 'company';
export const ENTITY_BANK = 'bank';
export const ENTITY_PRIVATE_COMPANY = 'privateCompany';
export const ENTITY_SUPPLIER = 'supplier';

export const BALANCE = 'balance';
export const INCOME_STATEMENT = 'p&l';
export const RATIOS = 'ratio';
export const RATIOS_CATEGORY = 'ratios';
export const TABLE_HEADERS = ['Field', 'Value'];
export const FILE_HEIGHT = 32;
export const FILE_WIDTH = 32;
export const MAX_IMAGE_SIZE = 5024;
export const FILE_EXTENSION = 'image/png';
export const FILE_PNG = 'png';
export const dateFormat = 'yyyy-MM-dd';
export const UserPrivileges = {
  canUseAI: 'can-use-ai',
  editAccounts: 'edit-accounts',
  viewAccounts: 'view-accounts',
};
