import { useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Grid,
  List,
  Paper,
  Button,
  Checkbox,
  ListItemButton,
  Typography,
} from '@mui/material';
import { ArrayIntersection, NotInArray } from 'utils';

const TransferList = ({ left, setLeft, letfTitle, right, setRight, rightTitle }) => {
  const [checked, setChecked] = useState([]);

  const leftChecked = ArrayIntersection(checked, left);
  const rightChecked = ArrayIntersection(checked, right);

  const handleToggle = (item) => () => {
    const currentIndex = checked.findIndex((element) => element.id === item.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(NotInArray(left, leftChecked));
    setChecked(NotInArray(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(NotInArray(right, rightChecked));
    setChecked(NotInArray(checked, rightChecked));
  };

  const customList = (items, header) => (
    <Paper sx={{ width: 200, height: 300, overflow: 'auto' }}>
      <List dense component='div' role='list'>
        <Typography>{header}</Typography>

        {items.map((item) => {
          const labelId = `transfer-list-item-${item.id}-label`;

          return (
            <ListItemButton key={item.id} role='listitem' onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((element) => element.id === item.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid item>{customList(left, letfTitle)}</Grid>

      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label='move all right'
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label='move all left'
          >
            ≪
          </Button>
        </Grid>
      </Grid>

      <Grid item>{customList(right, rightTitle)}</Grid>
    </Grid>
  );
};

export default TransferList;
