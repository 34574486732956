import styled from 'styled-components';

export const Loader = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1.5rem;
`;

export const ButtonLoader = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1.5rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ButtonsGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
