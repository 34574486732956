import {
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect, useState } from 'react';
import { ReportContext } from 'providers/ReportProvider';
import { toast } from 'react-toastify';
import { getAnalysisLLM } from 'services/ApiLLM';

export default function EditorLLM() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [lang, setLang] = useState('Spanish');
  const [analysisLLM, setAnalysisLLM] = useState('');
  const { income, balance, ratios, report } = useContext(ReportContext);

  useEffect(() => {
    setAnalysisLLM('');
  }, [report]);

  const normalizeData = (data) => {
    const normalized = {};
    data.forEach((item) => (normalized[item.field] = item.value));
    return normalized;
  };

  const handleClick = async () => {
    try {
      setIsDisabled(true);

      const payload = {
        company: report?.name,
        lang,
        data: {
          incomeStatement: normalizeData(income),
          balanceSheet: normalizeData(balance),
          ratios: normalizeData(ratios),
        },
      };

      const {
        data: { analysis },
      } = await getAnalysisLLM(payload);

      setAnalysisLLM(analysis);
      setIsDisabled(false);
    } catch (error) {
      toast.error('Cannot get AI Analysis');
      setIsDisabled(false);
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Experimental: LLM Analysis</Typography>
      </AccordionSummary>

      <Grid container p={2} gap={2} direction={'column'}>
        <Grid container gap={2}>
          <Button
            variant='contained'
            disabled={isDisabled}
            onClick={handleClick}
            sx={{ width: 200 }}
          >
            {isDisabled ? <CircularProgress /> : 'Analyze'}
          </Button>
          <TextField
            value={lang}
            label='Language'
            disabled
            onChange={(event) => setLang(event.target.value)}
          />
        </Grid>

        <Grid item xs>
          <TextField fullWidth rows={20} multiline value={analysisLLM} />
        </Grid>
      </Grid>
    </Accordion>
  );
}
