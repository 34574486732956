import React, { useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from './TextEditor';
import { ReportContext } from 'providers/ReportProvider';

const EditorSwot = () => {
  const {
    report,
    initialSwotStrengths,
    setInitalSwotStrengths,
    swotStrengths,
    setSwotStrengths,
    initialSwotWeaknesses,
    setInitialSwotWeaknesses,
    swotWeaknesses,
    setSwotWeaknesses,
    initialSwotOpportunities,
    setInitialSwotOpportunities,
    swotOpportunities,
    setSwotOpportunities,
    initialSwotThreats,
    setInitialSwotThreats,
    swotThreats,
    setSwotThreats,
  } = useContext(ReportContext);

  useEffect(() => {
    if (!report) return;
    const { swot } = report;

    setInitalSwotStrengths(swot.strengths);
    setInitialSwotWeaknesses(swot.weaknesses);
    setInitialSwotOpportunities(swot.opportunities);
    setInitialSwotThreats(swot.threats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>SWOT</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} direction={'column'}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Strengths</Typography>
          </AccordionSummary>

          <TextEditor
            initialText={initialSwotStrengths}
            editorState={swotStrengths}
            setEditorState={setSwotStrengths}
          />
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Weaknesses</Typography>
          </AccordionSummary>

          <TextEditor
            initialText={initialSwotWeaknesses}
            editorState={swotWeaknesses}
            setEditorState={setSwotWeaknesses}
          />
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Opportunities</Typography>
          </AccordionSummary>

          <TextEditor
            initialText={initialSwotOpportunities}
            editorState={swotOpportunities}
            setEditorState={setSwotOpportunities}
          />
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Threats</Typography>
          </AccordionSummary>

          <TextEditor
            initialText={initialSwotThreats}
            editorState={swotThreats}
            setEditorState={setSwotThreats}
          />
        </Accordion>
      </Grid>
    </Accordion>
  );
};

export default EditorSwot;
