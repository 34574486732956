import React, { createContext, useState, useEffect } from 'react';
import {
  getRegions,
  getCountries,
  getSectors,
  getMarkets,
  getProfiles,
  getCurrencies,
  getUnits,
  getBalanceItems,
  getIncomeItems,
  getRatiosItems,
  getEntityTypes,
  getClients,
} from 'services/ApiModels';
import { toast } from 'react-toastify';

export const ModelsContext = createContext();

export const ModelsProvider = ({ children }) => {
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [units, setUnits] = useState([]);
  const [balanceItems, setBalanceItems] = useState([]);
  const [incomeItems, setIncomeItems] = useState([]);
  const [ratiosItems, setRatiosItems] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('token')) return;

    const getFilterRegions = async () => {
      try {
        const {
          data: { regions },
        } = await getRegions();
        setRegions(regions);
      } catch (error) {
        throw error;
      }
    };

    const getFilterCountries = async () => {
      try {
        const {
          data: { countries },
        } = await getCountries();
        setCountries(countries);
      } catch (error) {
        throw error;
      }
    };

    const getFilterSectors = async () => {
      try {
        const {
          data: { sectors },
        } = await getSectors();
        setSectors(sectors);
      } catch (error) {
        throw error;
      }
    };

    const getFilterMarkets = async () => {
      try {
        const {
          data: { markets },
        } = await getMarkets();
        setMarkets(markets);
      } catch (error) {
        throw error;
      }
    };

    const getFilterProfiles = async () => {
      try {
        const {
          data: { profiles },
        } = await getProfiles();
        setProfiles(profiles);
      } catch (error) {
        throw error;
      }
    };

    const fetchCurrencies = async () => {
      try {
        const { data } = await getCurrencies();
        setCurrencies(data.currencies);
      } catch (error) {
        throw error;
      }
    };

    const fetchUnits = async () => {
      try {
        const { data } = await getUnits();
        setUnits(data.units);
      } catch (error) {
        throw error;
      }
    };

    const fetchBalanceItems = async () => {
      try {
        const { data } = await getBalanceItems();

        const { balanceBank, balanceCompany, balancePrivateCompany, balanceSupplier } =
          data.balanceItems;

        const bank = balanceBank.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const company = balanceCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const privateCompany = balancePrivateCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const supplier = balanceSupplier.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        setBalanceItems({ bank, company, privateCompany, supplier });
      } catch (error) {
        throw error;
      }
    };

    const fetchIncomeItems = async () => {
      try {
        const { data } = await getIncomeItems();

        const { incomeBank, incomeCompany, incomePrivateCompany, incomeSupplier } =
          data.incomeItems;

        const bank = incomeBank.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const company = incomeCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const privateCompany = incomePrivateCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const supplier = incomeSupplier.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        setIncomeItems({ bank, company, privateCompany, supplier });
      } catch (error) {
        throw error;
      }
    };

    const fetchRatiosItems = async () => {
      try {
        const { data } = await getRatiosItems();

        const { ratiosBank, ratiosCompany, ratiosPrivateCompany, ratiosSupplier } =
          data.ratiosItems;

        const bank = ratiosBank.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const company = ratiosCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const privateCompany = ratiosPrivateCompany.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        const supplier = ratiosSupplier.map((item, index) => ({
          id: index,
          value: 0,
          ...item,
        }));

        setRatiosItems({ bank, company, privateCompany, supplier });
      } catch (error) {
        throw error;
      }
    };

    const fetchEntityTypes = async () => {
      try {
        const {
          data: { entityTypes },
        } = await getEntityTypes();
        setEntityTypes(entityTypes);
      } catch (error) {
        throw error;
      }
    };

    const fetchClients = async () => {
      try {
        const {
          data: { clients },
        } = await getClients();
        setClients(clients);
      } catch (error) {
        throw error;
      }
    };

    const fetchAll = async () => {
      Promise.all([
        getFilterRegions(),
        getFilterCountries(),
        getFilterSectors(),
        getFilterMarkets(),
        getFilterProfiles(),
        fetchCurrencies(),
        fetchUnits(),
        fetchBalanceItems(),
        fetchIncomeItems(),
        fetchRatiosItems(),
        fetchEntityTypes(),
        fetchClients(),
      ]);
    };

    try {
      fetchAll();
    } catch (error) {
      toast.error('Dowload error. Please refresh the page');
    }
  }, []);

  const defaultValue = {
    regions,
    countries,
    sectors,
    markets,
    profiles,
    currencies,
    units,
    balanceItems,
    incomeItems,
    ratiosItems,
    entityTypes,
    clients,
  };

  return <ModelsContext.Provider value={defaultValue}>{children}</ModelsContext.Provider>;
};
