import React, { useContext, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReportContext } from 'providers/ReportProvider';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { addReport, deleteEntity, deleteEntityByDate } from 'services/ApiScore';
import { toast } from 'react-toastify';
import { convertToRaw } from 'draft-js';
import { useClearFilter } from 'hooks/useClearFilter';
import ModalDelete from 'components/ModalDelete';
import Loader from 'components/Loader';
import { EntitiesContext } from 'providers/EntitiesProvider';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { ShareholdersChartContext } from 'providers/ShareholdersChartProvider';
import { dateFormat } from 'constants';

const Menu = () => {
  const {
    entityType,
    dateOfInformation,
    updatedOn,
    observation,
    status,
    margin,
    rating,
    sp,
    moodys,
    fitch,
    outlook,
    regionId,
    countryId,
    marketId,
    profileId,
    sectorId,
    description,
    comments,
    swotStrengths,
    swotWeaknesses,
    swotOpportunities,
    swotThreats,
    currencyId,
    unitId,
    balance,
    income,
    ratios,
    debtItems,
    clearState,
    period,
    incomeChartItems,
    permissions,
    creditCapacity,
    suggestedDeadline,
    commentsResultsSupplier,
    sizeContract,
  } = useContext(ReportContext);

  const {
    levels,
    nodes,
    handleClear: clearShareholdersChart,
  } = useContext(ShareholdersChartContext);

  const { clearEntity, currentEntityId, showNav, setShowNav } = useContext(EntitiesContext);
  const { t } = useTranslation();
  const { clear } = useClearFilter();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteModalById, setOpenDeleteModalById] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { handleSubmit, setValue } = useFormContext();

  const handleAddClick = async (data) => {
    try {
      setIsUploading(true);

      const period = format(dateOfInformation, dateFormat);
      const updatedOnFormatted = format(updatedOn, dateFormat);

      const payload = {
        entity: {
          entityId: currentEntityId,
          name: data.entityName,
          type: entityType,
          countryId,
          sectorId,
          currencyId,
          unitId,
          description: JSON.stringify(convertToRaw(description.getCurrentContent())),
        },

        metadata: {
          scoreDescription: data.scoreDescription ?? '',
          regionId,
          marketId,
          profileId,
          observation,
          status,
          sp,
          moodys,
          fitch,
          outlook,
          margin,
          comments: JSON.stringify(convertToRaw(comments.getCurrentContent())),
          rating,
          strengths: JSON.stringify(convertToRaw(swotStrengths.getCurrentContent())),
          weakness: JSON.stringify(convertToRaw(swotWeaknesses.getCurrentContent())),
          opportunities: JSON.stringify(convertToRaw(swotOpportunities.getCurrentContent())),
          threats: JSON.stringify(convertToRaw(swotThreats.getCurrentContent())),
          creditCapacity,
          suggestedDeadline,
          commentsResultsSupplier,
          sizeContract,
          period,
          updatedOn: updatedOnFormatted,
        },

        indicators: {
          1: data.entityScore,
          ...income.reduce((acc, item) => ({ ...acc, [item.indicatorId]: item.value }), {}),
          ...balance.reduce((acc, item) => ({ ...acc, [item.indicatorId]: item.value }), {}),
          ...ratios.reduce((acc, item) => ({ ...acc, [item.indicatorId]: item.value }), {}),
        },

        debtMaturity: debtItems,

        incomeChart: incomeChartItems,

        shareholdersChart: {
          levels,
          nodes,
        },

        permissions: permissions.map((item) => item.id),
      };

      await addReport(payload);
      handleClear();
      toast.success('Entity uploaded');
    } catch (error) {
      console.log(error);
      toast.error(t('Error.Upload'));
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteByDateClick = async () => {
    try {
      if (!currentEntityId || period === 'All') {
        return toast.info('To delete: Select an entity and a period that is not "All"');
      }

      setIsPending(true);

      const payload = {
        entityId: currentEntityId,
        period,
      };
      await deleteEntityByDate(payload);

      handleClear();
      toast.success('Entity period deleted');
    } catch (error) {
      toast.error(`Error deleting entity period: ${error.response.data.message}`);
    } finally {
      setIsPending(false);
      setOpenDeleteModalById(false);
    }
  };

  const handleDeleteEntity = async (data) => {
    try {
      if (!currentEntityId && !data.entityName) {
        return toast.info('To delete: Select an entity');
      }

      setIsPending(true);

      const payload = {
        entityId: currentEntityId,
        entityName: data.entityName,
      };
      await deleteEntity(payload);
      handleClear();
      toast.success('Entity deleted');
    } catch (error) {
      toast.error('Error deleting entity');
    } finally {
      setIsPending(false);
      setOpenDeleteModal(false);
    }
  };

  const handleDelete = (type) => {
    if (!currentEntityId) return;

    if (type === 'byId') return setOpenDeleteModalById(true);
    return setOpenDeleteModal(true);
  };

  const handleClear = () => {
    clear();
    clearState();
    clearEntity();
    clearShareholdersChart();

    setValue('entityName', '');
    setValue('entityScore', 0);
    setValue('scoreDescription', '');
  };

  const handleTogglePanel = () => {
    setShowNav(!showNav);
  };

  return (
    <>
      <Grid alignSelf='center'>
        <Tooltip title={t('Toolbar.toggleLeftNav')} placement='top'>
          <span>
            <IconButton onClick={handleTogglePanel}>
              <SwitchLeftIcon color={`${!showNav ? 'success' : ''}`} />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Toolbar.add')} placement='top'>
          <IconButton onClick={handleSubmit(handleAddClick)}>
            {isUploading ? <Loader /> : <AddIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip title={t('Toolbar.clear')} placement='top'>
          <IconButton onClick={handleClear}>
            <LayersClearIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('Toolbar.deleteByDate')} placement='top'>
          <span>
            <IconButton onClick={() => handleDelete('byId')} disabled={isPending}>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Toolbar.deleteEntity')} placement='top'>
          <span>
            <IconButton onClick={handleDelete} disabled={isPending}>
              <DeleteForeverIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>

      <ModalDelete
        header='Delete Entity'
        message='Please confirn entity deletion. This cannot be reversed.'
        handleAccept={handleSubmit(handleDeleteEntity)}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        isPending={isPending}
      />
      <ModalDelete
        header='Delete Entity by date'
        message='Please confirn entity deletion. This cannot be reversed.'
        handleAccept={handleDeleteByDateClick}
        open={openDeleteModalById}
        setOpen={setOpenDeleteModalById}
        isPending={isPending}
      />
    </>
  );
};

export default Menu;
