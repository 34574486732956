import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import CustomTable from 'components/CustomTable';
import { ClientsContext } from 'providers/ClientsProvider';
import { headers } from './constants';
import { Menu } from './Menu';
import AddClientModal from './AddClientModal';

export default function Clients() {
  const { clients, setIsReloadClients, setIsOpenAddClientModal } = useContext(ClientsContext);

  const handleReloadUsersClick = () => {
    setIsReloadClients(true);
  };

  const handleAddUserClick = () => {
    setIsOpenAddClientModal(true);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography>{`Total clients (${clients.length})`} </Typography>
        <Menu onAdd={handleAddUserClick} onReload={handleReloadUsersClick} />
      </Box>

      <CustomTable data={clients} headers={headers} translation={'clients.table.headers'} />
      <AddClientModal />
    </Box>
  );
}
