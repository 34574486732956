import { Accordion, AccordionSummary, Divider, Grid, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect } from 'react';
import { ReportContext } from 'providers/ReportProvider';

const EditorResults = () => {
  const { report, creditCapacity, setCreditCapacity, suggestedDeadline, setSuggestedDeadline } =
    useContext(ReportContext);

  useEffect(() => {
    if (!report) return;

    const {
      description: { creditCapacity, suggestedDeadline },
    } = report;

    setCreditCapacity(creditCapacity);
    setSuggestedDeadline(suggestedDeadline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Results</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction={'column'}>
        <Grid item container gap={2}>
          <Grid item xs>
            <TextField
              label='Credit Capacity'
              fullWidth
              value={creditCapacity}
              onChange={(event) => {
                setCreditCapacity(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs>
            <TextField
              label='Suggested Deadline'
              fullWidth
              value={suggestedDeadline}
              onChange={(event) => {
                setSuggestedDeadline(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default EditorResults;
