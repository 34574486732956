import React, { useState, useEffect } from 'react';
import { Logo } from './CompanyLogo.styles';
import { getCompanyLogo } from 'services/ApiLogos';
import noIcon from 'assets/logos/noIcon.png';

export const CompanyLogo = ({ name }) => {
  const [logo, setLogo] = useState();

  useEffect(() => {
    setLogo(getCompanyLogo(name));
  }, [name]);

  return <Logo src={logo} onError={() => setLogo(noIcon)} alt='' />;
};
