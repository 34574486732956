import styled from 'styled-components';

export const LogoImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const AppName = styled.div`
  text-align: center;
  font-size: 1.5rem;  
`;
