import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const location = useLocation();

  if (localStorage.getItem('token')) return <Outlet />;
  return <Navigate to='/login' state={{ from: location }} replace />;
};

export default ProtectedRoute;
