import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import EditorDebtItem from './EditorDebItem';
import { v4 as uuid } from 'uuid';
import { ReportContext } from 'providers/ReportProvider';

const EditorDebt = () => {
  const { report, debtItems, setDebtItems } = useContext(ReportContext);

  const handleAddClick = () => {
    const item = {
      id: uuid(),
      debtProfile: 0,
      year: 0,
    };

    setDebtItems((prevState) => [...prevState, item]);
  };

  const handleDeleteItem = (id) =>
    setDebtItems(debtItems.filter((item) => item.id !== id));

  useEffect(() => {
    if (!report) return;

    const { debtMaturityChart } = report;

    if (!Object.entries(debtMaturityChart).length) return;

    const { categories, data } = debtMaturityChart;

    const items = categories.map((item, index) => ({
      id: index,
      debtProfile: data[index],
      year: item,
    }));

    setDebtItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleOnChange = (event, id, parentId) => {
    const value = event.target.value;
    const itemIndex = debtItems.findIndex((item) => item.id === parentId);
    const newDebItems = [...debtItems];
    if (id === 1) newDebItems[itemIndex].debtProfile = value;
    if (id === 2) newDebItems[itemIndex].year = value;
    setDebtItems(newDebItems);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Debt Maturity Profile Chart</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction='column'>
        <ButtonGroup>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>

          <IconButton onClick={() => setDebtItems([])}>
            <CachedIcon />
          </IconButton>
        </ButtonGroup>

        <Grid item container gap={2} direction='column'>
          {debtItems.map((item) => (
            <EditorDebtItem
              key={item.id}
              data={item}
              onDelete={() => handleDeleteItem(item.id)}
              onChange={handleOnChange}
            />
          ))}
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default EditorDebt;
