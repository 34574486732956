import React, { useContext } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EntitiesContext } from 'providers/EntitiesProvider';
import { useClearFilter } from 'hooks/useClearFilter';
import ReplayIcon from '@mui/icons-material/Replay';

export const Search = () => {
  const { t } = useTranslation();
  const { filter, setFilter, setCurrentPage } = useContext(EntitiesContext);
  const { clear } = useClearFilter();

  const handleChange = (event) => {
    const searchString = event.target.value;
    if (!searchString) return clear();
    const tempFilter = { ...filter };
    tempFilter.search = searchString;
    setFilter(tempFilter);
    setCurrentPage(0);
  };

  return (
    <TextField
      type='text'
      placeholder={`${t('Common.Search')}...`}
      onChange={handleChange}
      value={filter.search}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={clear}>
              <ReplayIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      sx={{ marginBottom: '1rem' }}
    />
  );
};
