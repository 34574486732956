import { createContext, useEffect, useState } from 'react';
import { getClients, getCountries } from 'services/ApiClients';

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [clients, setClients] = useState([]);
  const [isReloadClients, setIsReloadClients] = useState(false);
  const [isOpenAddClientModal, setIsOpenAddClientModal] = useState(false);

  const activeValues = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Yes' },
  ];

  const fetchCountries = async () => {
    try {
      const { data } = await getCountries();
      setCountries(data.countries);
    } catch (error) {
      throw error;
    }
  };

  const fetchClients = async () => {
    try {
      const { data } = await getClients();

      const normalizedData = data.clients.map((item) => ({
        ...item,
        active: !!item.active ? 'Yes' : 'No',
      }));

      setClients(normalizedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchClients();
  }, []);

  useEffect(() => {
    if (isReloadClients) {
      fetchClients();
      setIsReloadClients(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadClients]);

  const defaultValue = {
    countries,
    clients,
    isReloadClients,
    setIsReloadClients,
    isOpenAddClientModal,
    setIsOpenAddClientModal,
    activeValues,
  };

  return <ClientsContext.Provider value={defaultValue}>{children}</ClientsContext.Provider>;
};
