import { useEffect, useContext, useState } from 'react';
import { ReportContext } from 'providers/ReportProvider';
import { ENTITY_SUPPLIER } from 'constants';

export default function useIsSupplier() {
  const { entityType } = useContext(ReportContext);
  const [isSupplier, setIsSupplier] = useState(false);

  useEffect(() => {
    const value = entityType === ENTITY_SUPPLIER;
    setIsSupplier(value);
  }, [entityType]);

  return isSupplier;
}
