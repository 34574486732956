import React, { createContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { getUsers, getRoles, getClientCodes } from 'services/ApiUsers';

export const MIN_CHARS_FOR_SEARCH = 3;
export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [isReloadUsers, setIsReloadUsers] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [clientCodes, setClientCodes] = useState([]);
  const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
  const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const { lang } = JSON.parse(localStorage.getItem('userSettings'));
  i18next.changeLanguage(lang);

  const fetchUsers = async () => {
    try {
      setIsUsersLoading(true);
      const { data } = await getUsers(filter);
      setUsers(data.users);
      setUsersCount(data.users.length);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUsersLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const { data } = await getRoles();
      setRoles(data.roles);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClientCodes = async () => {
    try {
      const { data } = await getClientCodes();
      setClientCodes(data.clientCodes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    fetchClientCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter.length >= MIN_CHARS_FOR_SEARCH || !filter.length) fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (isReloadUsers) {
      fetchUsers();
      setIsReloadUsers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadUsers]);

  const defaultValue = {
    filter,
    setFilter,
    isOpenAddUserModal,
    setIsOpenAddUserModal,
    users,
    setUsers,
    setIsReloadUsers,
    usersCount,
    isUsersLoading,
    roles,
    clientCodes,
    isOpenDeleteUserModal,
    setIsOpenDeleteUserModal,
    isOpenEditUserModal,
    setIsOpenEditUserModal,
    currentUser,
    setCurrentUser,
  };

  return <UsersContext.Provider value={defaultValue}>{children}</UsersContext.Provider>;
};
