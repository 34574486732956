import { Route, Routes, Navigate } from 'react-router-dom';
import { Login, EditorPage, Settings, Accounts, Home, NotFound, Clients, Users } from 'pages';
import ProtectedRoute from './ProtectedRoute';

const RoutesList = () => {
  return (
    <Routes>
      <Route index path='/login' element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path='/' element={<Home />}>
          <Route path='/' element={<Navigate to='/editor' />} />
          <Route path='/editor' element={<EditorPage />} />
          <Route path='/accounts' element={<Accounts />}>
            <Route index path='/accounts' element={<Navigate to='/accounts/clients' />} />
            <Route path='clients' element={<Clients />} />
            <Route path='users' element={<Users />} />
          </Route>
          <Route path='/settings' element={<Settings />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RoutesList;
