import React, { useContext } from 'react';
import logo from 'assets/logos/score32.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import { Box, IconButton, Typography } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const TopBar = ({ brand }) => {
  const navigate = useNavigate();
  const { logoutUser, getUserName } = useContext(AuthContext);

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        padding: '0.1rem',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <img src={logo} alt='' style={{ height: '32px' }} />
      <Typography variant='h5'>{brand}</Typography>
      <Typography sx={{ fontSize: '0.7rem', flex: 1 }}>
        V.{process.env.REACT_APP_VERSION}
      </Typography>

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          padding: '0.3rem 1rem',
          borderRadius: '1rem',
          lineHeight: '1rem',
        }}
      >
        {getUserName()}
      </Box>

      <IconButton onClick={handleLogout}>
        <PowerSettingsNewIcon color='success' />
      </IconButton>
    </Box>
  );
};

export default TopBar;
