import { useEffect, useContext, useState } from 'react';
import { ReportContext } from 'providers/ReportProvider';
import { ENTITY_PRIVATE_COMPANY } from 'constants';

const useIsPrivateCompany = () => {
  const { entityType } = useContext(ReportContext);
  const [isPrivateCompany, setIsPrivateCompany] = useState(false);

  useEffect(() => {
    const value = entityType === ENTITY_PRIVATE_COMPANY;
    setIsPrivateCompany(value);
  }, [entityType]);

  return isPrivateCompany;
};

export default useIsPrivateCompany;
