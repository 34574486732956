import axios from 'axios';
import conf from 'config';

const ApiClient = axios.create({
  baseURL: `${conf.baseUrl}/${conf.baseAPI}`,
  headers: { 'Content-Type': 'application/json' },
});

const interceptorsConf = (config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

ApiClient.interceptors.request.use(interceptorsConf);

ApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.error('Authentication error redirecting to Login');
      window.location = '/';
    }
  }
);

const ApiClientUpload = axios.create({
  baseURL: `${conf.baseUrl}/${conf.baseAPI}`,
  headers: { 'Content-Type': 'multipart/form-data' },
});

ApiClientUpload.interceptors.request.use(interceptorsConf);

export { ApiClient, ApiClientUpload };
