import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useContext } from 'react';
import { ShareholdersChartContext } from 'providers/ShareholdersChartProvider';

const NodeItem = ({
  id,
  title,
  parentId,
  onDelete,
  onChangeTitle,
  levelIndex,
  parents,
}) => {
  const { handleChangeNodeParent } = useContext(ShareholdersChartContext);

  const handleParentChange = (event) => {
    handleChangeNodeParent(event, id);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'divider',
      }}
    >
      <TextField
        value={title}
        onChange={(event) => onChangeTitle(event, id)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => onDelete(id)}>
                <DeleteForeverIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {levelIndex !== 0 && (
        <TextField
          select
          label='Parent'
          sx={{ marginTop: '1rem' }}
          value={parentId}
          onChange={handleParentChange}
        >
          {parents.map((parent) => (
            <MenuItem key={parent.id} value={parent.id}>
              {parent.title}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Box>
  );
};

export default NodeItem;
