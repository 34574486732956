import styled from 'styled-components';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const GridLayout = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(8, auto);
  margin-bottom: 0.5rem;
`;

export const GridHeader = styled.div`
  display: contents;

  & > span {
    border-bottom: solid 2px ${(props) => props.theme.palette.divider};
    background-color: ${(props) => props.theme.palette.background.default};
    padding: 0.5rem;
    position: sticky;
    top: -1rem;
    display: grid;
    gap: 0.1rem;
    grid-auto-flow: column;
    padding: 0 0.1rem;
    align-items: center;
  }
`;

export const GridRow = styled.div`
  display: contents;
  cursor: pointer;

  & span {
    word-wrap: break-word;
    padding: 0.2rem;
    background-color: ${(props) =>
      props.isCurrent ? props.theme.palette.success.main : 'transparent'};
  }

  &:hover > span {
    background-color: ${(props) => props.theme.palette.success.dark};
  }

  &:hover:nth-of-type(2n) > span {
    background-color: ${(props) => props.theme.palette.success.dark};
  }

  &:nth-of-type(2n) > span {
    background-color: ${(props) =>
      props.isCurrent ? props.theme.palette.success.main : props.theme.palette.divider};
  }
`;

export const Sorting = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const ArrowUp = styled(KeyboardArrowUpIcon)`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props) => (props.disabled ? props.theme.palette.background.default : 'white')};
`;

export const ArrowDown = styled(KeyboardArrowDownIcon)`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props) => (props.disabled ? props.theme.palette.background.default : 'white')};
`;

export const LoaderIcon = styled.img``;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 1 / -1;
  margin-top: 2rem;
`;
