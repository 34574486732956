import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from 'components/Search';

export const EntitiesCount = ({ count }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Search />
      <div>
        <span>{t('Entities.title')}: </span>
        <span>({count})</span>
      </div>
    </div>
  );
};
