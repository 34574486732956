import { useContext } from 'react';
import ClientForm from './ClientForm';
import { ClientsContext } from 'providers/ClientsProvider';
import CustomModal from 'components/CustomModal';
import { defaultValues } from './constants';

const AddClientModal = () => {
  const {
    isOpenAddClientModal,
    setIsOpenAddClientModal,
    countries,
    activeValues,
    setIsReloadClients,
  } = useContext(ClientsContext);

  const handleClose = () => {
    setIsOpenAddClientModal(false);
  };

  const handleSuccess = () => {
    setIsReloadClients(true);
  };

  return (
    <div>
      <CustomModal title={'Add Client'} onClose={handleClose} open={isOpenAddClientModal}>
        <ClientForm
          countries={countries}
          activeValues={activeValues}
          onClose={handleClose}
          defaultValues={defaultValues}
          successCallback={handleSuccess}
        />
      </CustomModal>
    </div>
  );
};

export default AddClientModal;
