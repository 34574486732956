export const camelCase = (str) => {
  return str
    .trim()
    .split(' ')
    .map((item, index) => {
      if (index === 0) return firstToLower(item);
      return firstToUpper(item);
    })
    .join('');
};

export const firstToLower = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const firstToUpper = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const parseRecoverToken = (text) => {
  return !text ? null : /code=(.*)/.exec(text)[1];
};

export const parseIndicator = (indicator, period = 'All') => {
  if (!indicator) return '';

  if (period === 'All') {
    const length = indicator.data.length - 1;
    return indicator.data[length].value.toLocaleString('en-US');
  } else {
    const [filter] = indicator.data.filter((item) => item.period === period);

    if (!filter) return '';
    return filter.value.toLocaleString('en-US');
  }
};

export const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      URL.revokeObjectURL(file);
      resolve({
        width: image.width,
        height: image.height,
      });
    };

    image.onerror = (error) => {
      reject(error);
    };
    image.src = URL.createObjectURL(file);
  });
};

export const getFileExtension = (file) => {
  return file.name.split('.')[1];
};

export const NotInArray = (a, b) => a.filter((item) => b.indexOf(item) === -1);

export const ArrayIntersection = (a, b) => a.filter((item) => b.indexOf(item) !== -1);

const getIndicatorsColors = (theme) => {
  return {
    blue: theme.palette.blue,
    red: theme.palette.red,
    yellow: theme.palette.yellow,
    green: theme.palette.green,
    none: '',
  };
};

export const scoreColor = (value, theme) => {
  const colors = getIndicatorsColors(theme);

  if (value < 3.0 && value > 0) {
    return colors.red;
  } else if (value >= 3.0 && value < 3.8) {
    return colors.blue;
  } else if (value >= 3.8) {
    return colors.green;
  } else {
    return colors.none;
  }
};

export const removeSpaces = (str) => {
  return str.replace(/\s/g, '');
};

export const getRandomPassword = (length = 16) => {
  const alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%&';

  let randomPassword = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (alphabet.length - 1));
    randomPassword += alphabet.charAt(randomIndex);
  }

  return randomPassword;
};
