import { ApiClient } from './ApiClient';

export const getRegions = () => ApiClient.get('/models/admin/regions');

export const getCountries = () => ApiClient.get('/models/admin/countries');

export const getSectors = () => ApiClient.get('/models/admin/sectors');

export const getMarkets = () => ApiClient.get('/models/admin/markets');

export const getProfiles = () => ApiClient.get('/models/admin/profiles');

export const getOrderBy = () => ApiClient.get('/models/orderBy');

export const getCurrencies = () => ApiClient.get('/models/currencies');

export const getUnits = () => ApiClient.get('/models/units');

export const getBalanceItems = () => ApiClient.get('/models/balanceItems');

export const getIncomeItems = () => ApiClient.get('/models/incomeItems');

export const getRatiosItems = () => ApiClient.get('/models/ratiosItems');

export const getEntityTypes = () => ApiClient.get('/models/entityTypes');

export const getClients = () => ApiClient.get('/models/clients');
