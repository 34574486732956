import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({    
    detection:{
      order:['navigator']
    },
    fallbackLng: 'en',
    whitelist:['en', 'es'],
    debug: true,
    resources: {
      en: {translation: require('locales/en/translationEn.json')},
      es: {translation: require('locales/es/translationEs.json')}
    }
  });


export default i18n;