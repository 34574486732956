import React, { createContext, useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { EditorState } from 'draft-js';
import { ModelsContext } from 'providers/ModelsProvider';

export const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
  const { regions, countries, sectors, markets, profiles, currencies, units, entityTypes } =
    useContext(ModelsContext);

  const [report, setReport] = useState(null);
  const [period, setPeriod] = useState('All');
  const [periods, setPeriods] = useState(['All']);
  const [entityType, setEntityType] = useState('');
  const [dateOfInformation, setDateOfInformation] = useState(new Date());
  const [updatedOn, setUpdatedOn] = useState(new Date());
  const [observation, setObservation] = useState('');
  const [status, setStatus] = useState('');
  const [margin, setMargin] = useState('');
  const [rating, setRating] = useState('');
  const [sp, setSP] = useState('');
  const [moodys, setMoodys] = useState('');
  const [fitch, setFitch] = useState('');
  const [outlook, setOutlook] = useState('');
  const [regionId, setRegionId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [marketId, setMarketId] = useState('');
  const [profileId, setProfileId] = useState('');
  const [sectorId, setSectorId] = useState('');
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [initialDescription, setInitialDescription] = useState('');
  const [comments, setComments] = useState(EditorState.createEmpty());
  const [initialComments, setInitialComments] = useState('');
  const [initialSwotStrengths, setInitalSwotStrengths] = useState('');
  const [swotStrengths, setSwotStrengths] = useState(EditorState.createEmpty());
  const [initialSwotWeaknesses, setInitialSwotWeaknesses] = useState('');
  const [swotWeaknesses, setSwotWeaknesses] = useState(EditorState.createEmpty());
  const [initialSwotOpportunities, setInitialSwotOpportunities] = useState('');
  const [swotOpportunities, setSwotOpportunities] = useState(EditorState.createEmpty());
  const [initialSwotThreats, setInitialSwotThreats] = useState('');
  const [swotThreats, setSwotThreats] = useState(EditorState.createEmpty());
  const [currencyId, setCurrencyId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [balance, setBalance] = useState([]);
  const [income, setIncome] = useState([]);
  const [ratios, setRatios] = useState([]);
  const [debtItems, setDebtItems] = useState([]);
  const [incomeChartItems, setIncomeChartItems] = useState([]);
  const [creditCapacity, setCreditCapacity] = useState('');
  const [suggestedDeadline, setSuggestedDeadline] = useState('');
  const [commentsResultsSupplier, setCommentsResultsSupplier] = useState('');
  const [sizeContract, setSizeContract] = useState('');

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('userSettings')) {
      const { lang } = JSON.parse(localStorage.getItem('userSettings'));
      i18next.changeLanguage(lang);
      return;
    }
  }, []);

  const clearState = () => {
    setReport(null);
    setPeriod('All');
    setPeriods(['All']);
    setEntityType(entityTypes[1].name);
    setUpdatedOn(new Date());
    setDateOfInformation(new Date());
    setObservation('');
    setStatus('');
    setMargin('');
    setRating('');
    setSP('');
    setMoodys('');
    setFitch('');
    setOutlook('');
    setRegionId(regions[0].id);
    setCountryId(countries[0].id);
    setMarketId(markets[0].id);
    setProfileId(profiles[0].id);
    setSectorId(sectors[0].id);
    setDescription(EditorState.createEmpty());
    setInitialDescription('');
    setComments(EditorState.createEmpty());
    setInitialComments('');
    setSwotStrengths(EditorState.createEmpty());
    setInitalSwotStrengths('');
    setSwotWeaknesses(EditorState.createEmpty());
    setInitialSwotWeaknesses('');
    setSwotOpportunities(EditorState.createEmpty());
    setInitialSwotOpportunities('');
    setSwotThreats(EditorState.createEmpty());
    setInitialSwotThreats('');
    setCurrencyId(currencies[0].id);
    setUnitId(units[0].id);
    const intialBalance = balance.map((item) => ({ ...item, value: 0 }));
    setBalance(intialBalance);
    const intialIncome = income.map((item) => ({ ...item, value: 0 }));
    setIncome(intialIncome);
    const intialRatios = ratios.map((item) => ({ ...item, value: 0 }));
    setRatios(intialRatios);
    setDebtItems([]);
    setIncomeChartItems([]);
    setCreditCapacity('');
    setSuggestedDeadline('');
    setCommentsResultsSupplier('');
    setSizeContract('');
    setPermissions([]);
  };

  const defaultValue = {
    report,
    setReport,
    period,
    setPeriod,
    periods,
    setPeriods,
    entityType,
    setEntityType,
    dateOfInformation,
    setDateOfInformation,
    updatedOn,
    setUpdatedOn,
    observation,
    setObservation,
    status,
    setStatus,
    margin,
    setMargin,
    rating,
    setRating,
    sp,
    setSP,
    moodys,
    setMoodys,
    fitch,
    setFitch,
    regionId,
    setRegionId,
    countryId,
    setCountryId,
    marketId,
    setMarketId,
    profileId,
    setProfileId,
    sectorId,
    setSectorId,
    description,
    setDescription,
    comments,
    setComments,
    initialComments,
    setInitialComments,
    initialDescription,
    setInitialDescription,
    initialSwotStrengths,
    setInitalSwotStrengths,
    swotStrengths,
    setSwotStrengths,
    initialSwotWeaknesses,
    setInitialSwotWeaknesses,
    swotWeaknesses,
    setSwotWeaknesses,
    initialSwotOpportunities,
    setInitialSwotOpportunities,
    swotOpportunities,
    setSwotOpportunities,
    initialSwotThreats,
    setInitialSwotThreats,
    swotThreats,
    setSwotThreats,
    currencyId,
    setCurrencyId,
    unitId,
    setUnitId,
    balance,
    setBalance,
    income,
    setIncome,
    ratios,
    setRatios,
    debtItems,
    setDebtItems,
    clearState,
    outlook,
    setOutlook,
    incomeChartItems,
    setIncomeChartItems,
    creditCapacity,
    setCreditCapacity,
    suggestedDeadline,
    setSuggestedDeadline,
    commentsResultsSupplier,
    setCommentsResultsSupplier,
    sizeContract,
    setSizeContract,
    permissions,
    setPermissions,
  };

  return <ReportContext.Provider value={defaultValue}>{children}</ReportContext.Provider>;
};
