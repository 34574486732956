import { createContext, useState, useEffect } from 'react';

export const ThemeUIContext = createContext();

export const ThemeUIProvider = ({ children }) => {
  const [mode, setMode] = useState('dark');

  useEffect(() => {
    if (localStorage.getItem('userSettings')) {
      const { theme } = JSON.parse(localStorage.getItem('userSettings'));
      if (theme) setMode(theme);
    }
  }, []);

  const defaultValue = {
    mode,
    setMode,
  };

  return (
    <ThemeUIContext.Provider value={defaultValue}>
      {children}
    </ThemeUIContext.Provider>
  );
};
