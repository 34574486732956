import React from 'react';
import { AppName, LogoImage } from './Logo.styles';
import ScoReLogo from 'assets/logos/score64.png';
import { useTranslation } from 'react-i18next';

const Logo = () => {
  const { t } = useTranslation();

  return (
    <>
      <LogoImage src={ScoReLogo} />
      <AppName>{t('App.name')}</AppName>
    </>
  );
};

export default Logo;
