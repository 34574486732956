import { useTranslation } from 'react-i18next';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const Search = ({ label, value, handleOnChangeSearch, handleClearFilterClick }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <TextField
        id='search'
        label={label}
        value={value}
        onChange={handleOnChangeSearch}
        type='text'
        style={{ width: '300px' }}
        size='small'
      />

      <Tooltip title={t('Toolbar.clear')} placement='top'>
        <IconButton onClick={handleClearFilterClick}>
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Search;
