import React, { createContext } from 'react';
import { checkAuth as checkAuthorization } from 'services/AuthApi';
import { LOCALSTORAGE_ITEMS } from 'constants';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const logoutUser = () => LOCALSTORAGE_ITEMS.map((item) => localStorage.removeItem(item));

  const getUserName = () => localStorage.getItem('username');
  const getUserPrivileges = () => JSON.parse(localStorage.getItem('privileges'));

  const checkAuth = async () => {
    try {
      const { data } = await checkAuthorization();
      const { isAuth } = data;
      return isAuth;
    } catch (error) {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ logoutUser, getUserName, getUserPrivileges, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
