import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomModal from 'components/CustomModal';
import Loader from 'components/Loader';

const ModalDelete = ({ header, message, handleAccept, open, setOpen, isPending }) => {
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <CustomModal open={open} onClose={handleClose} title={header}>
      <>
        <p>{message}</p>
        <ButtonGroup>
          <Button variant='contained' onClick={handleAccept}>
            {isPending ? <Loader /> : t('Common.Delete')}
          </Button>
          <Button variant='outlined' onClick={handleClose}>
            {t('Common.Cancel')}
          </Button>
        </ButtonGroup>
      </>
    </CustomModal>
  );
};

export default ModalDelete;
