import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginatorContainer } from './EntitiesPaginator.styles';
import { ITEMS_PAGE } from 'constants';

export const EntitiesPaginator = ({
  onPageChange,
  entitiesCount,
  currentPage,
}) => {
  return (
    <PaginatorContainer>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(entitiesCount / ITEMS_PAGE)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
        pageLinkClassName={'active-link'}
        forcePage={currentPage}
      />
    </PaginatorContainer>
  );
};
