import React, { useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from './TextEditor';
import { ReportContext } from 'providers/ReportProvider';
import { useTranslation } from 'react-i18next';

const EditorComments = () => {
  const { report, comments, setComments, initialComments, setInitialComments } =
    useContext(ReportContext);
  const { t } = useTranslation();

  useEffect(() => {
    setInitialComments(report?.comments);
  }, [report, setInitialComments]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('Editor.comments')}</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction={'column'}>
        <TextEditor
          initialText={initialComments}
          editorState={comments}
          setEditorState={setComments}
        />
      </Grid>
    </Accordion>
  );
};

export default EditorComments;
