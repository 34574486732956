import React, { useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  TextField,
  Divider,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReportContext } from 'providers/ReportProvider';
import { ModelsContext } from 'providers/ModelsProvider';

const EditorUnits = () => {
  const { report, currencyId, setCurrencyId, unitId, setUnitId } = useContext(ReportContext);
  const { currencies, units } = useContext(ModelsContext);

  const handleInputChange = (event, setter) => setter(event.target.value);

  useEffect(() => {
    if (!report) return;
    const { currency: currencyResult, units: unitsResult } = report;
    setCurrencyId(currencyResult.id);
    setUnitId(unitsResult.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Currency and Units</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2}>
        <Grid item xs>
          <TextField
            select
            label='Currency'
            fullWidth
            value={currencyId}
            onChange={(event) => handleInputChange(event, setCurrencyId)}
          >
            {currencies.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.name} | ${item.code}  (${item.symbol})`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs>
          <TextField
            select
            label='Units Magnitud'
            fullWidth
            value={unitId}
            onChange={(event) => handleInputChange(event, setUnitId)}
          >
            {units.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.name} (${item.code})`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default EditorUnits;
