import styled from 'styled-components';
import { device, lefBarWidth } from 'styles/size';

export const EditorLayout = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.showNav ? `${lefBarWidth} 1fr` : '1fr')};
  min-height: 100vh;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;
