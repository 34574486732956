import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, Button, Grid, Box, TextField, Divider } from '@mui/material';
import { useUserSettings } from 'hooks/useUserSettings';
import i18next from 'i18next';
import { changePassword } from 'services/AuthApi';
import { toast } from 'react-toastify';
import { updateSettings } from 'services/ApiUser';
import { ThemeUIContext } from 'providers/ThemeUIProvider';

const Settings = () => {
  const { t } = useTranslation();
  const { lang } = useUserSettings();
  const [language, setLanguage] = useState(lang);
  const { mode, setMode } = useContext(ThemeUIContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const updateUserSettings = async (key, value) => {
    try {
      const payload = {
        key,
        value,
      };

      await updateSettings(payload);

      const settings = JSON.parse(localStorage.getItem('userSettings'));
      settings[key] = value;
      localStorage.setItem('userSettings', JSON.stringify(settings));
    } catch (error) {
      toast.error(t('Error.Update'));
    }
  };

  const languageHandleChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    i18next.changeLanguage(lang);
    updateUserSettings('lang', lang);
  };

  const themeHandleChange = (event) => {
    const theme = event.target.value;
    setMode(theme);
    updateUserSettings('theme', theme);
  };

  const validateChangePassword = (payload) => {
    const { password, newPassword: newPass } = payload;
    if (!password || !newPass) return false;
    if (newPass !== repeatPassword) return false;
    return true;
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        password: currentPassword,
        newPassword: newPassword,
      };

      const isValidPayload = validateChangePassword(payload);
      if (!isValidPayload) throw new Error();

      await changePassword(payload);

      toast.success(t('Success.Password changed'));
    } catch (error) {
      toast.error(t('Error.Invalid credentials'));
    }
  };

  const handleChangeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPasswordChange = (event) => {
    setRepeatPassword(event.target.value);
  };

  return (
    <Box sx={{ p: 2, height: '100vh' }}>
      <Box>
        <Header header={t('Settings.changePassword.header')} />
        <Divider />

        <Box sx={{ pt: 2 }}>
          <form>
            <Grid container direction='column' gap={2} sx={{ maxWidth: '350px' }}>
              <TextField
                id='current-password'
                label={t('Settings.changePassword.currentPassword')}
                type='password'
                autoComplete='current-password'
                value={currentPassword}
                onChange={handleChangeCurrentPassword}
              />

              <TextField
                id='new-password'
                label={t('Settings.changePassword.newPassword')}
                type='password'
                value={newPassword}
                onChange={handleNewPasswordChange}
              />

              <TextField
                id='repeat-password'
                label={t('Settings.changePassword.repeatPassword')}
                type='password'
                value={repeatPassword}
                onChange={handleRepeatPasswordChange}
              />

              <Button onClick={handleChangePassword} variant='contained' fullWidth>
                {t('Settings.changePassword.change')}
              </Button>
            </Grid>
          </form>
        </Box>

        <Header header={t('Settings.language.header')} />
        <Divider />
        <Box>
          <RadioGroup
            aria-label='language'
            name='language'
            value={language}
            onChange={languageHandleChange}
          >
            <FormControlLabel
              value='en'
              control={<Radio color='primary' />}
              label={t('Settings.language.english')}
              style={{ width: 300 }}
            />
            <FormControlLabel
              value='es'
              control={<Radio color='primary' />}
              label={t('Settings.language.spanish')}
              style={{ width: 300 }}
            />
          </RadioGroup>
        </Box>

        <Header header={t('Settings.theme.header')} />
        <Divider />

        <Box>
          <RadioGroup aria-label='palette' name='palette' value={mode} onChange={themeHandleChange}>
            <FormControlLabel
              value='dark'
              control={<Radio color='primary' />}
              label={t('Settings.theme.dark')}
              style={{ width: 300 }}
            />
            <FormControlLabel
              value='light'
              control={<Radio color='primary' />}
              label={t('Settings.theme.light')}
              style={{ width: 300 }}
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
