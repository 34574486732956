import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 0;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.text.primary};
  outline: 0;
  padding: 1rem;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
