import React, { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ITEMS_PAGE, MIN_SEARCH_CHARS } from 'constants';
import { getEntitiesGrid } from 'services/ApiScore';

export const EntitiesContext = createContext();

export const EntitiesProvider = ({ children }) => {
  const [filter, setFilter] = useState({ search: '', advanced: '' });
  const [entities, setEntities] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [entitiesCount, setEntitiesCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentEntityId, setCurrentEntityId] = useState(null);
  const [showNav, setShowNav] = useState(true);

  const clearEntity = () => {
    setCurrentEntityId(null);
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) return;

    const populateEntities = async () => {
      if (!Number.isInteger(currentPage)) return;
      if (filter.search && filter.search.length < MIN_SEARCH_CHARS) return;

      try {
        setIsPending(true);
        const offset = currentPage * ITEMS_PAGE;
        const { data } = await getEntitiesGrid(ITEMS_PAGE, offset, filter);

        const { entities } = data;

        if (!entities) {
          setEntities([]);
          setEntitiesCount(0);
          return;
        }

        const { list, count } = entities;

        setEntities(list);
        setEntitiesCount(count);
      } catch (error) {
        toast.error('Error loading entities');
      } finally {
        setIsPending(false);
      }
    };

    populateEntities();
  }, [currentPage, filter]);

  const defaultValue = {
    filter,
    setFilter,
    entities,
    setEntities,
    entitiesCount,
    setEntitiesCount,
    currentPage,
    setCurrentPage,
    currentEntityId,
    setCurrentEntityId,
    showNav,
    setShowNav,
    clearEntity,
    isPending,
  };

  return (
    <EntitiesContext.Provider value={defaultValue}>
      {children}
    </EntitiesContext.Provider>
  );
};
