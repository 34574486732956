import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import EditorIncomeChartItem from './EditorIncomeChartItem';
import { v4 as uuid } from 'uuid';
import { ReportContext } from 'providers/ReportProvider';

const EditorIncomeChart = () => {
  const { report, incomeChartItems, setIncomeChartItems } =
    useContext(ReportContext);

  const handleAddItemClick = () => {
    const item = {
      id: uuid(),
      title: '',
      value: 0,
    };

    setIncomeChartItems((prevState) => [...prevState, item]);
  };

  const handleDeleteItem = (id) =>
    setIncomeChartItems(incomeChartItems.filter((item) => item.id !== id));

  useEffect(() => {
    if (!report) return;

    const { incomeChart } = report;

    if (!incomeChart) return;

    if (!Object.entries(incomeChart).length) return;

    const { data } = incomeChart;

    const items = data.map((item, index) => ({
      id: index,
      title: item.title,
      value: item.value,
    }));

    setIncomeChartItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleOnChange = (event, id, parentId) => {
    const value = event.target.value;
    const itemIndex = incomeChartItems.findIndex(
      (item) => item.id === parentId
    );
    const newItems = [...incomeChartItems];
    if (id === 1) newItems[itemIndex].title = value;
    if (id === 2) newItems[itemIndex].value = value;
    setIncomeChartItems(newItems);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Income distribution Chart</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction='column'>
        <ButtonGroup>
          <IconButton onClick={handleAddItemClick}>
            <AddIcon />
          </IconButton>

          <IconButton onClick={() => setIncomeChartItems([])}>
            <CachedIcon />
          </IconButton>
        </ButtonGroup>

        <Grid item container gap={2} direction='column'>
          {incomeChartItems.map((item) => (
            <EditorIncomeChartItem
              key={item.id}
              data={item}
              onDelete={() => handleDeleteItem(item.id)}
              onChange={handleOnChange}
            />
          ))}
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default EditorIncomeChart;
