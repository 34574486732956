import { Accordion, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransferList from 'components/Transferlist';
import { useContext, useEffect, useState } from 'react';
import { ModelsContext } from 'providers/ModelsProvider';
import { ReportContext } from 'providers/ReportProvider';

const EditorPermissions = () => {
  const { clients } = useContext(ModelsContext);
  const { permissions, setPermissions } = useContext(ReportContext);

  const [nonAuthorized, setNonAuthorized] = useState([]);

  useEffect(() => {
    const nonAuth = clients.filter(
      (client) => !permissions.some((permission) => client.id === permission.id)
    );
    setNonAuthorized(nonAuth);
  }, [clients, permissions]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Permissions</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction={'column'}>
        <TransferList
          left={nonAuthorized}
          setLeft={setNonAuthorized}
          letfTitle='Non-Authorized'
          rightTitle='Authorized'
          right={permissions}
          setRight={setPermissions}
        />
      </Grid>
    </Accordion>
  );
};

export default EditorPermissions;
