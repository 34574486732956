import styled from 'styled-components';
import { device } from 'styles/size';

export const Layout = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  min-height: 100vh;

  @media ${device.tablet} {
    grid-template-rows: auto auto auto 1fr;
  }
`;
