import { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { v4 as uuid } from 'uuid';
import LevelItem from './LevelItem';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@mui/system';
import HighchartsOrganization from 'highcharts/modules/organization';
import HighchartsSankey from 'highcharts/modules/sankey';
import { ShareholdersChartContext } from 'providers/ShareholdersChartProvider';
import { ReportContext } from 'providers/ReportProvider';

HighchartsSankey(Highcharts);
HighchartsOrganization(Highcharts);

const EditorShareholdersCharts = () => {
  const {
    levels,
    setLevels,
    nodes,
    setNodes,
    getParents,
    chartData,
    handleClear,
  } = useContext(ShareholdersChartContext);

  const { report } = useContext(ReportContext);

  useEffect(() => {
    handleClear();
    if (!report) return;

    const { shareholdersChart } = report;

    if (!shareholdersChart) return;

    if (!Object.entries(shareholdersChart).length) return;

    const { levels, nodes } = shareholdersChart;

    setLevels(levels);
    setNodes(nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleAddLevel = () => {
    const index = levels.length;

    const newLevel = {
      id: uuid(),
      index,
    };

    setLevels((prevState) => [...prevState, newLevel]);
  };

  const deleteLevel = (id) => {
    const newLevels = levels.filter((item) => item.id !== id);

    const reIndexedLevels = newLevels.map((level, index) => ({
      ...level,
      index,
    }));

    setLevels(reIndexedLevels);

    const newNodes = nodes.filter((node) => node.levelId !== id);
    setNodes(newNodes);
  };

  const addNode = (levelId, levelIndex) => {
    const newNode = {
      id: uuid(),
      parentId: levelId === 0 ? null : '',
      levelId,
      levelIndex,
      title: '',
    };

    setNodes((prevState) => [...prevState, newNode]);
  };

  const deleteNode = (nodeId) => {
    const newNodes = nodes.filter(
      (node) => node.id !== nodeId && node.parentId !== nodeId
    );
    setNodes(newNodes);
  };

  const handleChangeNodeTitle = (event, nodeId) => {
    const newNodes = structuredClone(nodes);
    const currentNodeIndex = newNodes.findIndex((node) => node.id === nodeId);
    newNodes[currentNodeIndex].title = event.target.value;
    setNodes(newNodes);
  };

  const chartOptions = {
    chart: {
      inverted: true,
      margin: 10,
      width: 500,
      height: 200,
    },

    accessibility: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },

    series: [
      {
        type: 'organization',
        name: 'Shareholders',
        keys: ['from', 'to'],
        data: chartData,
        colorByPoint: false,
        borderColor: 'silver',
        color: 'none',
        nodeWidth: 20,
        borderWidth: 2,
        dataLabels: {
          color: 'black',
          style: {
            fontSize: 10,
          },
          padding: 1,
        },
      },
    ],
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Shareholders Chart</Typography>
      </AccordionSummary>

      <Divider />

      <Grid container padding={2} gap={2} direction='column'>
        <ButtonGroup>
          <IconButton onClick={handleAddLevel}>
            <AddIcon />
          </IconButton>

          <IconButton onClick={handleClear}>
            <CachedIcon />
          </IconButton>
        </ButtonGroup>

        <Grid item container gap={2} direction='column'>
          {levels.map((item, index) => {
            return (
              <LevelItem
                key={item.id}
                levelId={item.id}
                onDelete={() => deleteLevel(item.id)}
                levelIndex={index}
                onAddNode={addNode}
                nodes={nodes.filter((node) => node.levelId === item.id)}
                onDeleteNode={deleteNode}
                onChangeNodeTitle={handleChangeNodeTitle}
                parents={getParents(index)}
              />
            );
          })}
        </Grid>

        {nodes.length > 0 && (
          <Grid item container justifyContent='center'>
            <Box>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Accordion>
  );
};

export default EditorShareholdersCharts;
