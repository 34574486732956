import React, { useContext } from 'react';
import { LeftBarLayout } from './LeftBar.styles';
import { EntitiesContext } from 'providers/EntitiesProvider';
import { EntitiesCount } from 'components/EntitiesCount';
import { EntitiesPaginator } from 'components/EntitiesPaginator';
import { Entities } from 'components/Entities';
import { Typography } from '@mui/material';

const LeftBar = ({ onPageChange }) => {
  const { filter, showNav, currentPage, entities, entitiesCount } =
    useContext(EntitiesContext);

  return (
    <LeftBarLayout isVisible={showNav}>
      <EntitiesCount count={entitiesCount} />

      {entitiesCount > 0 ? (
        <EntitiesPaginator
          onPageChange={onPageChange}
          entitiesCount={entitiesCount}
          currentPage={currentPage}
        />
      ) : (
        <Typography>No Results</Typography>
      )}

      <Entities entities={entities} filter={filter} currentPage={currentPage} />
    </LeftBarLayout>
  );
};

export default LeftBar;
