import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';

const TextEditor = ({ initialText, editorState, setEditorState }) => {
  const onEditorStateChange = (editorState) => setEditorState(editorState);

  useEffect(() => {
    if (!initialText) return setEditorState(EditorState.createEmpty());
    let parsedText = '';

    try {
      parsedText = JSON.parse(initialText);
    } catch (error) {
      parsedText = initialText;
    }

    if (typeof parsedText === 'string') {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(parsedText))
      );
    } else {
      setEditorState(EditorState.createWithContent(convertFromRaw(parsedText)));
    }
  }, [initialText, setEditorState]);

  return (
    <Editor
      editorState={editorState}
      toolbarClassName='toolbarClassName'
      wrapperClassName='wrapperClassName'
      editorClassName='editorClassName'
      editorStyle={{
        height: 'auto',
        border: '1px solid',
        padding: '0 1rem',
      }}
      onEditorStateChange={onEditorStateChange}
      toolbarStyle={{ color: 'black' }}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'colorPicker',
          'history',
        ],
      }}
      handlePastedText={() => false}
    />
  );
};

export default TextEditor;
