import { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import EditorEntity from './EditorEntity';
import EditorGeneralInfo from './EditorGeneralInfo';
import EditorComments from './EditorComments';
import EditorSwot from './EditorSwot';
import EditorUnits from './EditorUnits';
import EditorIncomeStatement from './EditorIncomeStatement';
import EditorBalanceSheet from './EditorBalanceSheet';
import EditorRatios from './EditorRatios';
import EditorDebt from './DebtChart/EditorDebt';
import EditorIncomeChart from './IncomeChart/EditorIncomeChart';
import EditorShareholdersCharts from './ShareholdersChart';
import useIsPrivateCompany from 'hooks/useIsPrivateCompany';
import EditorPermissions from './EditorPermissions';
import EditorResults from './EditorResults';
import Toolbar from 'components/Toolbar/Toolbar';
import EditorScoreAI from './EditorScoreAI';
import EditorLLM from './EditorLLM';
import { Grid } from '@mui/material';
import { UserPrivileges } from 'constants';
import useIsSupplier from 'hooks/useIsSupplier';
import EditorResultsSupplier from './EditorResultsSupplier';

const Fundamentals = (
  <>
    <EditorUnits />
    <EditorIncomeStatement />
    <EditorBalanceSheet />
    <EditorRatios />
  </>
);

const EditorPrivateCompany = (
  <>
    {Fundamentals}
    <EditorResults />
  </>
);

const EditorSupplier = (
  <>
    {Fundamentals}
    <EditorResultsSupplier />
  </>
);

const EditorScore = (
  <>
    <EditorSwot />
    {Fundamentals}
    <EditorDebt />
    <EditorIncomeChart />
    <EditorShareholdersCharts />
  </>
);

const EditorForm = () => {
  const isPrivateCompany = useIsPrivateCompany();
  const isSupplier = useIsSupplier();
  const { getUserPrivileges } = useContext(AuthContext);
  const privileges = getUserPrivileges();
  const canUseAI = privileges.includes(UserPrivileges.canUseAI);

  const renderComponentByEntityType = () => {
    if (isPrivateCompany) return EditorPrivateCompany;
    if (isSupplier) return EditorSupplier;
    return EditorScore;
  };

  return (
    <Grid sx={{ overflowY: 'auto', height: 'calc(100vh - 100px)' }}>
      <Toolbar />
      <EditorEntity />
      <EditorGeneralInfo />
      <EditorComments />
      {canUseAI && <EditorLLM />}
      {renderComponentByEntityType()}
      <EditorPermissions />
      {canUseAI && <EditorScoreAI />}
    </Grid>
  );
};

export default EditorForm;
