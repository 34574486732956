import React from 'react';
import Table from 'components/Table';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Box } from '@mui/material';
import { TABLE_HEADERS } from 'constants';

const EditorIncomeChartItem = ({ data, onDelete, onChange }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Table
        headers={TABLE_HEADERS}
        rows={[
          { id: 1, field: 'Title', value: data.title },
          { id: 2, field: 'Value (%)', value: data.value },
        ]}
        onChange={onChange}
        parentId={data.id}
      />

      <Box sx={{ display: 'flex', alignSelf: 'flex-start' }}>
        <IconButton onClick={onDelete}>
          <DeleteForeverIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default EditorIncomeChartItem;
