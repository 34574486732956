import { useContext } from 'react';
import UsersGrid from './components/UsersGrid';
import { UsersContext } from 'providers/UsersProvider';
import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import EditUserModal from './components/EditUserModal';
import { Menu } from './components/Menu';
import { Box } from '@mui/material';

export default function Users() {
  const {
    setIsOpenAddUserModal,
    setIsReloadUsers,
    setIsOpenEditUserModal,
    setIsOpenDeleteUserModal,
    setCurrentUser,
  } = useContext(UsersContext);

  const handleReloadUsersClick = () => {
    setIsReloadUsers(true);
    setCurrentUser({});
  };

  const handleAddUserClick = () => {
    setIsOpenAddUserModal(true);
  };

  const handleEditUserClick = () => {
    setIsOpenEditUserModal(true);
  };

  const handleDeleteUserClick = () => {
    setIsOpenDeleteUserModal(true);
  };

  return (
    <Box>
      <Menu
        onAdd={handleAddUserClick}
        onReload={handleReloadUsersClick}
        onEdit={handleEditUserClick}
        onDelete={handleDeleteUserClick}
      />
      <UsersGrid />

      <AddUserModal />
      <DeleteUserModal />
      <EditUserModal />
    </Box>
  );
}
