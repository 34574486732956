import { Card, Divider, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import NodeItem from './NodeItem';

const LevelItem = ({
  onDelete,
  levelIndex,
  nodes,
  onAddNode,
  levelId,
  onDeleteNode,
  onChangeNodeTitle,
  parents,
}) => {
  const handleAddNode = () => {
    onAddNode(levelId, levelIndex);
  };

  return (
    <Card>
      <Box sx={{ display: 'flex', paddingTop: '1rem' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            padding: '0 1rem',
          }}
        >
          <Typography>Level {levelIndex + 1}</Typography>

          <IconButton onClick={handleAddNode}>
            <AddIcon />
          </IconButton>
        </Box>

        <IconButton onClick={onDelete}>
          <DeleteForeverIcon />
        </IconButton>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          padding: '1rem',
          flexWrap: 'wrap',
        }}
      >
        {nodes.map((node) => {
          return (
            <NodeItem
              id={node.id}
              key={node.id}
              title={node.title}
              onDelete={onDeleteNode}
              onChangeTitle={onChangeNodeTitle}
              levelIndex={levelIndex}
              parents={parents}
              parentId={node.parentId}
            />
          );
        })}
      </Box>
    </Card>
  );
};

export default LevelItem;
