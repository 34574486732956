import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from './NotFound.styles';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Typography variant='h5'>{t('Error.NotFound')}</Typography>
    </Layout>
  );
};

export default NotFound;
