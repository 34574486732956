import { Box, Tab, Tabs } from '@mui/material';
import TopBar from 'components/TopBar';
import { UserPrivileges } from 'constants';
import { AuthContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';

const commonStyle = {
  px: 0,
  py: 1,
  minHeight: 0,
};

export default function Home() {
  const { t } = useTranslation();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const { getUserPrivileges } = useContext(AuthContext);
  const privileges = getUserPrivileges();
  const hasAccountsAccess = privileges.includes(
    UserPrivileges.viewAccounts,
    UserPrivileges.editAccounts
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TopBar brand={t('App.name')} />

      <Box>
        <Tabs value={value} onChange={handleChange} sx={commonStyle}>
          <Tab label='Editor' component={Link} to='/editor' value='/editor' sx={commonStyle} />
          {hasAccountsAccess && (
            <Tab
              label='Accounts'
              component={Link}
              to='/accounts'
              value='/accounts'
              sx={commonStyle}
            />
          )}
          <Tab
            label='Settings'
            component={Link}
            to='/settings'
            value='/settings'
            sx={commonStyle}
          />
        </Tabs>
        <Outlet />
      </Box>
    </>
  );
}
