import React, { useContext } from 'react';
import {
  GridLayout,
  GridHeader,
  GridRow,
  Sorting,
  ArrowUp,
  ArrowDown,
  Loader,
  LoaderIcon,
} from './styles';
import { UsersContext } from 'providers/UsersProvider';
import loader from 'assets/loaders/loader.svg';
import { Box } from '@mui/material';

const UsersGrid = () => {
  const { users, setUsers, isUsersLoading, setCurrentUser, currentUser } = useContext(UsersContext);

  const handleSorting = (name, direction) => {
    const key = name.toLowerCase();
    let sorted = [];
    let copy = JSON.parse(JSON.stringify(users));

    switch (direction) {
      case 'up':
        sorted = copy.sort((a, b) => {
          if (!isNaN(a[key])) return a[key] - b[key];
          return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1;
        });
        break;
      case 'down':
        sorted = copy.sort((a, b) => {
          if (!isNaN(a[key])) return b[key] - a[key];
          return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1;
        });
        break;
      default:
        return;
    }
    setUsers(sorted);
  };

  const handleClickUser = (id) => {
    if (currentUser.id === id) {
      setCurrentUser({});
    } else {
      const userData = users.find((user) => user.id === id);
      setCurrentUser(userData);
    }
  };

  return (
    <Box sx={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
      <GridLayout>
        <GridHeader>
          <span>
            <span>Username</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('username', 'up')} />
              <ArrowDown onClick={() => handleSorting('username', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Role</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('role', 'up')} />
              <ArrowDown onClick={() => handleSorting('role', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Email</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('email', 'up')} />
              <ArrowDown onClick={() => handleSorting('email', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Client</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('client_code', 'up')} />
              <ArrowDown onClick={() => handleSorting('client_code', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Code</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('client_name', 'up')} />
              <ArrowDown onClick={() => handleSorting('client_name', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Last login</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('last_login', 'up')} />
              <ArrowDown onClick={() => handleSorting('last_login', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Login Count</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('login_count', 'up')} />
              <ArrowDown onClick={() => handleSorting('login_count', 'down')} />
            </Sorting>
          </span>

          <span>
            <span>Active</span>
            <Sorting>
              <ArrowUp onClick={() => handleSorting('Active', 'up')} />
              <ArrowDown onClick={() => handleSorting('Active', 'down')} />
            </Sorting>
          </span>
        </GridHeader>

        {!isUsersLoading ? (
          users.map(
            ({
              id,
              username,
              role,
              email,
              client_name,
              client_code,
              last_login,
              login_count,
              active,
            }) => (
              <GridRow
                key={id}
                onClick={() => handleClickUser(id)}
                isCurrent={id === currentUser.id}
              >
                <React.Fragment key={id}>
                  <span>{username}</span>
                  <span>{role}</span>
                  <span>{email}</span>
                  <span>{client_name}</span>
                  <span>{client_code}</span>
                  <span>{new Date(last_login).toLocaleString(navigator.language)}</span>
                  <span>{login_count}</span>
                  <span>{active ? 'Yes' : 'No'}</span>
                </React.Fragment>
              </GridRow>
            )
          )
        ) : (
          <Loader>
            <LoaderIcon src={loader} />
          </Loader>
        )}
      </GridLayout>
    </Box>
  );
};

export default UsersGrid;
