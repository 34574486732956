import React from 'react';
import { ToolbarLayout } from './Toolbar.styles';
import Menu from 'components/Menu';
import { AppBar } from '@mui/material';

const Toolbar = () => {
  return (
    <AppBar position='sticky'>
      <ToolbarLayout>
        <Menu />
      </ToolbarLayout>
    </AppBar>
  );
};

export default Toolbar;
