const environments = {};
const baseAPI = 'api/v1';
const devBackendPort = 9081;

environments.development = {
  baseUrl: `http://localhost:${devBackendPort}`,
  baseAPI,
  envName: 'development',
};

environments.production = {
  baseUrl: 'https://tisas.score.onl',
  baseAPI,
  envName: 'production',
};

const currentEnv =
  typeof process.env.NODE_ENV === 'string' ? process.env.NODE_ENV.toLowerCase() : '';

const config =
  typeof environments[currentEnv] === 'object'
    ? environments[currentEnv]
    : environments.development;

export default config;
