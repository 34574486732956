export const headers = [
  {
    id: 'id',
    isHidden: true,
  },
  {
    id: 'name',
  },
  {
    id: 'shortname',
  },
  {
    id: 'code',
  },

  {
    id: 'country',
  },
  {
    id: 'active',
  },
];

export const defaultValues = {
  country: 'COL',
  active: 1,
};
